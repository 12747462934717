import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import IMPORTLIST from '@/constants/importPage';
import useUpload from '@/hooks/uploadWiki';
import { chooseFileAndUpload } from '@/utils/index';
import { Menu, Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { useDispatch, useSelector } from 'react-redux';

import { useContext } from 'react';
import styles from './style.module.less';
import importWikiModal from './ImportWikiModal';

const cx = classBind.bind(styles);

function ImportChoose({ pageId, pageName, clickImport }) {
  const { knowledgeId } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const { openFileModal } = dispatch.fileTree;

  // eslint-disable-next-line no-unused-vars
  const { addUploadTask, deleteFile } = useUpload();
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);

  const chooseImport = async (flag) => {
    clickImport && clickImport();
    if (flag === 'cooper') {
      // 目录里导入
      openFileModal({ knowledgeId, pageId, pageName });
      window.__OmegaEvent('ep_dkpc_pagemore_import_cooper_ck');
      return;
    }
    if (flag === 'confluence') {
      importWikiModal({knowledgeId, pageId, addUploadTask})
      return;
    }
    chooseFileAndUpload(flag, ({ file, type }) => {
      if (type === 'word') {
        addUploadTask(file, {
          knowledgeId,
          currPageId: 0,
          parentId: pageId,
          type: 'word',
        });
      } else {
        addUploadTask(file, {
          knowledgeId,
          currPageId: 0,
          parentId: pageId,
          type: 'confluence',
          modeType: flag === 'confluence' ? 1 : 0,
        });
      }
    });
  };

  const goToWikiHelp = (e) => {
    e?.stopPropagation();
    window.open(globalOutsideChain?.import_wiki_help, '_blank');
  };

  return (
    <Menu.ItemGroup>
      {IMPORTLIST().map((item) => {
        return (
          <Menu.Item
            key={item.key}
            className={cx('import-li')}>
            <span
              className={cx('import-li-content')}
              onClick={() => chooseImport(item.menuFlag)}
            >
              <img
                src={item.image}
                alt={item.title}
                className={cx('li-image')}
              />

              <div className={cx('li-text')}>
                <div className={cx('li-title')}>
                  {item.title}
                  {item.menuFlag === 'confluence' && (
                    <Tooltip
                      title={<span>{intl.t('查看')}<a style={{fontWeight: 500}} onClick={goToWikiHelp}>{intl.t('wiki迁移帮助文档')}</a></span>}
                      placement="top"
                      overlayClassName="dk-ant-tooltip__reset_v2"
                      color="#FFF"
                    >
                      <i
                        className={cx(
                          'dk-icon-tishi-xianxing',
                          'dk-iconfont',
                          'import-help',
                        )}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className={cx('li-desc')}>{item.desc}</div>
              </div>
            </span>
          </Menu.Item>
        );
      })}
    </Menu.ItemGroup>
  );
}

export default ImportChoose;
