/* eslint-disable */
import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react';
import classBind from 'classnames/bind';
import { Tabs } from 'antd';
import { getSearchDataDK, getRecentData } from '@/service/knowledge/search';
import { isDesktopDC } from '@/utils';
import InfiniteScroll from 'react-infinite-scroller';
import { MODE_PREFIX } from '@/constants';
import SearchItem from '@/components/SearchItemDk';
import SpinRender from '@/components/SpinRender';
import DKCard from '@/pages/knowledge/MenHu/MyKnowledge/DKCard';
import styles from './style.module.less';
import { intl } from 'di18n-react';

const cx = classBind.bind(styles);
const { TabPane } = Tabs;
const searchMap = {
  'allPages': 0,
  'pages': 1,
  'metis': 2,
  'files': 3
}

const EmptyRender = ({ text }) => (
  <div className={cx('search-page-empty')}>
    <div style={{ textAlign: 'center' }}>
      <img src='http://img-ys011.didistatic.com/static/cooper_cn/noSearchResult.png' />
      {text || intl.t('未搜索到相关结果，请更换关键词')}
    </div>
  </div>
);
const NoMore = () => <div className={cx('search-empty')}>
  {intl.t('已经到底了～')}
</div>;

const HistoryResultList = ({ data, loading, afterClickItem, api }) => (
  <>
    <div className={cx('search-page-tab')}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={intl.t("header-高级搜索-最近浏览")} key="1" />
      </Tabs>
    </div>
    <ul>
      {data?.map((item, index) => (
        <SearchItem key={index} data={item} origin="ep_dkpc_dk_advancedsearch_recentview_ck" callback={afterClickItem} api={api} wrapClass={cx('item-wrap')} />
      ))}
      <SpinRender loading={loading} />
      {!loading && !data?.length && <EmptyRender text={intl.t("header-高级搜索-没有最近访问记录")} />}
    </ul>
    {!loading && !!data?.length && <NoMore />}
  </>
);

//知识库的搜索
const MetisRender = ({ data, searchType, setSearchType, api = {} }) => (
  <li className={cx('search-metis')} key="metis">
    {searchType == 'allPages' && !!data.length && (
      <p className={cx('search-metis-title')}>
        <span>
          {intl.t('知识库')}
        </span>
        {data.length > 4 && (
          <span onClick={() => setSearchType('metis')} style={{ cursor: 'pointer' }}>
            {intl.t('查看更多知识库')}
            <i className='dk-iconfont'>&#xe706;</i>
          </span>
        )}
      </p>
    )}
    <ul className={cx('search-metis-wrap')}>
      {data.slice(0, 4).map((dkInfo, index) => (
        <DKCard dkInfo={dkInfo} key={index} prefix={MODE_PREFIX[api?.mode] || ''} />
      ))}
    </ul>
  </li>
);

const Result = (props) => {
  const filter = props.value;
  const { api } = props;
  const [data, setData] = useState([]);
  const [metisData, setMetisData] = useState([]);
  const [searchType, setSearchType] = useState('allPages');
  const [total, setTotal] = useState({ page: 0, metis: 0 });
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const fetchRef = useRef(0);
  const isRecent = useMemo(() => {
    if (filter.scope || filter.time || filter.owner || filter.key || filter.tags) return false;
    return true;
  }, [filter]);

  const getSearchList = async (pageNum = 0, initData, initMetisData) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    let currentData = initData ?? data;
    let currentMetisData = initMetisData ?? metisData;
    setLoading(true);
    const opts = {
      pageNum,
      pageSize: 20,
      searchType: searchMap[searchType],
      key: filter?.key?.trim(),
      time: filter.time,
      owner: filter.owner,
      tagIds: filter.tags,
      metisIds: filter.scope,
      fileType: searchType === 'files' ? filter.fileType : ''
    }
    let res = api.search ? await api.search(opts) : await getSearchDataDK(opts);


    window.__OmegaEvent('ep_dkpc_dk_advancedsearch_ck', '搜索', {
      searchtype:searchType,
      key: filter.key,
      time: filter.time,
      owner: filter.owner,
      metisids: filter.scope,
    });

    if (fetchId !== fetchRef.current) {
      setLoading(false);
      return;
    }

    // 数据检查,这里检查知识库是因为搜索类型为全部的时候需要知识库
    if (!Array.isArray(res?.[searchType]?.items) || !Array.isArray(res?.metis?.items)) {
      setLoading(false);
      return;
    }

    // metis 数据格式处理
    if (searchType === 'allPages' || searchType === 'metis') {
      let metisDatatmp = res.metis.items.map((v) => ({
        name: v.resourceName,
        id: v.metisId,
        picture: v.resourceTinyImage,
        desc: v.metisDesc,
        relationTypeTags: v.relationTypeTags,
      }));
      setMetisData([...currentMetisData, ...metisDatatmp]);
    }

    if (res[searchType].items.length < 20) {
      setHasMore(false);
    }

    setData([...currentData, ...res[searchType].items]);
    setTotal({ page: res[searchType].totalCount, metis: res.metis.totalCount });
    setLoading(false);
  };

  const afterClickItem = () => {
    !isDesktopDC && props.close();
  }

  const handleSearchTypeUpdate = (v) => {
    setSearchType(v);
    v === 'pages' && window.__OmegaEvent('ep_dkpc_dk_advancedsearch_recentview_page_ck');
    v === 'metis' && window.__OmegaEvent('ep_dkpc_dk_advancedsearch_recentedit_ck');
    v === 'files' && window.__OmegaEvent('ep_dkpc_dk_advancedsearch_file_ck');
  }

  const getRecentList = async () => {
    setHistoryList([]);
    setLoading(true);
    try {
      const res = api.recent ? await api.recent() : await getRecentData();
      setHistoryList(res ?? []);
      setHasMore(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isRecent) {
      getRecentList();
    } else {
      setHasMore(true);
      setTotal({ page: 0, metis: 0 });
      setData([]);
      setMetisData([]);
      getSearchList(0, [], []);
    }
    if (searchType === 'files') {
      document.querySelector('#filter-fileType').style.display = 'block'
    } else {
      document.querySelector('#filter-fileType').style.display = 'none'
    }
  }, [searchType, filter]);

  return (
    <div className={cx('search-page-result')}>
      {isRecent ? (
        <HistoryResultList data={historyList} loading={loading} afterClickItem={afterClickItem} api={api} />
      ) : (
        <>
          <div className={cx('search-page-tab')}>
            <Tabs activeKey={searchType} onChange={handleSearchTypeUpdate}>
              <TabPane tab={intl.t('全部')} key="allPages" />
              <TabPane tab={intl.t('页面')} key="pages" />
              <TabPane tab={intl.t('知识库')} key="metis" />
              <TabPane tab={intl.t('文件')} key="files" />
            </Tabs>
          </div>
          <p className={cx('search-page-total')}>
            {intl.t('共')}
            {(searchType == 'metis') ? total.metis : (total.page + total.metis)}
            {intl.t('条结果')}
          </p>
          {metisData.length + data.length > 0 && (
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={getSearchList}
              hasMore={!loading && hasMore}
              useWindow={false}
              getScrollParent={() => document.querySelector('#search-page-main')}
            >
              {searchType != 'metis' && (
                <ul>
                  {data.map((v, i) => {
                    if (i == 4) {
                      return (
                        <Fragment key={i}>
                          {metisData.length > 0 && (
                            <MetisRender
                              data={metisData}
                              searchType={searchType}
                              setSearchType={setSearchType}
                              api={api}
                            />
                          )}
                          <SearchItem data={v} callback={afterClickItem} api={api} wrapClass={cx('item-wrap')} />
                        </Fragment>
                      );
                    }
                    return <SearchItem key={i} data={v} callback={afterClickItem} api={api} wrapClass={cx('item-wrap')} />;
                  })}
                  {total.page <= 4 && metisData.length > 0 && (
                    <MetisRender
                      data={metisData}
                      searchType={searchType}
                      setSearchType={setSearchType}
                      api={api}
                    />
                  )}
                </ul>
              )}
              {searchType == 'metis' && (
                <ul className={`${cx('search-metis-wrap')} ${cx('searchDK')}`}>
                  {metisData.map((dkInfo, index) => (
                    <DKCard dkInfo={dkInfo} key={index} prefix={MODE_PREFIX[api?.mode] || ''} />
                  ))}
                </ul>
              )}
              {!hasMore && !loading && !!total && (
                <div className={cx('search-empty')}>
                  {intl.t('没有更多了')}
                </div>
              )}
            </InfiniteScroll>
          )}
          <SpinRender loading={loading} />
          {data.length + metisData.length == 0 && !loading && <EmptyRender />}
        </>
      )}
    </div>
  );
};

export default Result;
