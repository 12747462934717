import { intl, getLocale } from 'di18n-react';
import React from 'react';
import { Modal, Button } from 'antd';
import { openVpnPage } from '../utils';
import iconCup from '../images/PC-配图.svg';
import './index.less';

const toVpnLink = () =>
  location.toString().replace(location.host, 'cooper-vpn.xiaojukeji.com');

class NsModal extends React.Component {
  state = { step: 0 };

  render() {
    const { step } = this.state;
    const { onClose } = this.props;
    const isEn = getLocale() === 'en-US';

    return (
      <Modal
        className='ns-modal'
        width={480}
        footer={null}
        closable={false}
        visible
      >
        <i className='icon-close' onClick={onClose} />
        {step === 0 ? (
          <div className='step-0'>
            <img src={iconCup} alt='cup' />
            <p className='title'>{intl.t('公网环境暂时不开放此功能')}</p>
            <p>{intl.t('系统检测到您当前使用的是非办公网络，')}</p>
            <p>{intl.t('为保障您的文件数据安全，建议连接公司 VPN 后使用')}</p>
            <button
              className='btn-link'
              onClick={() => this.setState({ step: 1 })}
            >
              {intl.t('查看公网环境说明')}
            </button>
            <Button type='primary' onClick={openVpnPage}>
              {intl.t('下载 VPN 客户端')}
            </Button>
            <p className='foot-tip'>
              {intl.t('连接 VPN 后仍然无法打开，请')}
              <a href={toVpnLink()}>{intl.t('点击此处')}</a>
              {intl.t('进行访问')}
            </p>
          </div>
        ) : (
          <div className={`step-1${isEn ? ' is-en' : ''}`}>
            <i
              className='icon-back'
              onClick={() => this.setState({ step: 0 })}
            />
            <p className='title'>{intl.t('在公网环境您可以')}</p>
            <p>{intl.t('• 在个人空间内上传文件/文件夹、新建文件夹')}</p>
            <p>{intl.t('• 查看首页、个人空间、团队空间首页等列表')}</p>
            <p>{intl.t('• 申请个人空间和团队空间的扩容')}</p>
            <p>{intl.t('• 在消息中心中进行快速审批处理')}</p>
            <p>{intl.t('• 搜索文件、文档和文件夹')}</p>
            <Button type='primary' onClick={openVpnPage}>
              {intl.t('下载 VPN 客户端')}
            </Button>
            <p className='foot-tip'>
              {intl.t('连接 VPN 后仍然无法打开，请')}
              <a href={toVpnLink()}>{intl.t('点击此处')}</a>
              {intl.t('进行访问')}
            </p>
          </div>
        )}
      </Modal>
    );
  }
}

export default NsModal;
