import { isDkSheet, openNewWindow } from '@/utils';
import { useParams } from 'react-router-dom';
import { intl } from 'di18n-react';
import { useMemo, useState, useContext } from 'react';
import classBind from 'classnames/bind';
import { Popover, Menu } from 'antd';
import RenameInput from './RenameInput';
import { CopyOutlined } from '@ant-design/icons';
import { OPERATE_CONTENT_DATA } from '@/constants/catalog';
import usePermission from '@/hooks/usePermission';
import deleteNode from '../DeleteModal/index';
import styles from './style.module.less';
import { useDispatch, useSelector } from 'react-redux';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';

const cx = classBind.bind(styles);

function OperatePopover({
  operateStep,
  pageId,
  pageName,
  doDelete,
  doRename,
  doCopy,
  pagePerm,
  children,
  type,
  fullPath,
  setOperateStep,
  doStar,
  nodeData,
  // getPopupContainer = () => {},
}) {
  const [newName, setNewName] = useState('');
  const { teamId } = useParams();
  // const [perm, setPerm] = useState();
  const { checkOperationPermission } = usePermission();
  const { knowledgeId } = useContext(LayoutContext);
  const { knowledgeDetail, permission } = useSelector(
    (state) => state.KnowledgeData,
  );
  const dispatch = useDispatch();
  const { openTransferModal } = dispatch.transfer;
  const { markedStar } = nodeData;
  /**
   * 打开popover showul
   * 点击rename rename
   * rename完成 hidden
   * 点击空白处 hidden
   * 点击删除  hidden
   */
  const isSheet = isDkSheet(type);
  const clickMenu = (e) => {
    if (e.key === 'rename') {
      setOperateStep(OPERATE_CONTENT_DATA.showRenameStep);
      window.__OmegaEvent('ep_dkpc_page_rename_ck');
    } else if (e.key === 'delete') {
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
      deleteNode({
        doDelete,
        pageName,
      });
      window.__OmegaEvent('ep_dkpc_pagemore_delete_ck');
    } else if (e.key === 'copy') {
      doCopy();
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
      window.__OmegaEvent('ep_dkpc_pagemore_copy_ck');
    } else if (e.key === 'star') {
      doStar();
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
    } else if (e.key === 'remove') {
      openTransferModal({
        knowledgeId,
        pageId,
        modalTitle:
          pageName === intl.t('确认删除-知识库') ? knowledgeDetail?.spaceName : pageName,
        knowledgeName: knowledgeDetail?.spaceName,
        visible: true,
        checkable: false,
        fullPath,
        isOwner: checkOperationPermission(
          'MANAGE_DK_ADVANCED_SETTING',
          permission.perm,
        ),
        modalText: intl.t('知识库所有者支持跨库移动，非所有者只能在库内移动'),
      });
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
      window.__OmegaEvent('ep_dkpc_pagemore_move_ck');
    } else if (e.key === 'open') {
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
      openNewWindow(`/knowledge/${knowledgeId}/${pageId}`);
    }
  };

  const onVisibleChange = (visible) => {
    // 打开弹窗
    if (visible) {
      setOperateStep(OPERATE_CONTENT_DATA.showUlContentStep);
      window.__OmegaEvent('ep_dkpc_pagemore_ck');
    } else {
      setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);

      if (newName.length > 0) {
        doRename(newName);
        setNewName('');
      }
    }
  };

  const onPressEnter = (enterName) => {
    setOperateStep(OPERATE_CONTENT_DATA.hiddenStep);
    doRename(enterName);
    setNewName('');
  };

  const changePageName = (inputValue) => {
    setNewName(inputValue);
  };

  const checPerm = (page) => {
    if (!pagePerm) return false;
    let res = false;
    page && !checkOperationPermission(page, pagePerm) && (res = true);
    return res;
  };

  const OperateList = useMemo(() => {
    return (
      <div className={cx('menu-wrap')}>
        <Menu
          className={cx('operate-ul')}
          selectable={false}
          onClick={clickMenu}
          mode="vertical"
          triggerSubMenuAction="hover"
        >
          <Menu.Item
            key="open"
            disabled={false}
            className={cx('operate-li')}
            icon={
              <i
                className={cx(
                  'dk-icon-icon_browser',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('在新标签页打开')}
          </Menu.Item>
          <Menu.Item
            key="rename"
            disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
            className={cx('operate-li')}
            icon={
              <i
                className={cx(
                  'dk-icon-zhongmingming',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('重命名')}
          </Menu.Item>
          {
            isSheet || (
              <Menu.Item
                key="copy"
                disabled={checPerm('MANAGE_PAGE_CONTEXT_LIMIT')}
                className={cx('operate-li')}
                icon={<CopyOutlined style={{ fontSize: '14px', color: '#494F55' }} />}
              >
                {intl.t('创建副本')}
              </Menu.Item>
            )
          }
          <Menu.Item
            key="remove"
            disabled={checPerm('MANAGE_PAGE_SETTING_LIMIT')}
            className={cx('operate-li')}
            icon={
              <i
                className={cx(
                  'dk-icon-qianchuyemian',
                  'operate-icon-pop',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('移动到')}
          </Menu.Item>
          <Menu.Item
            key="star"
            className={cx('operate-li')}
            icon={
              <i
                className={cx({
                  'dk-icon-shoucang4px': !markedStar,
                  'operate-icon-pop': true,
                  'dk-iconfont': true,
                  'dk-icon-yishoucang1': markedStar,
                  hasStar: markedStar,
                  'star-btn': true,
                })}
              />
            }
          >
            {markedStar ? intl.t('取消收藏') : intl.t('收藏')}
          </Menu.Item>
          <Menu.Item
            key="delete"
            disabled={checPerm('MANAGE_PAGE_SETTING_LIMIT')}
            className={cx('operate-li', 'operate-li-dangerous')}
            icon={
              <i
                className={cx(
                  'dk-icon-shanchu',
                  'operate-icon-pop',
                  'operate-icon-pop-del',
                  'dk-iconfont',
                )}
              />
            }
          >
            {intl.t('删除')}
          </Menu.Item>
        </Menu>
      </div>
    );
  }, [pageId, doCopy, deleteNode]);

  const RenameContent = useMemo(() => {
    return (
      <RenameInput
        type={type}
        pressEnter={onPressEnter}
        defaultValue={pageName}
        changePageName={changePageName}
      />
    );
  }, [pageName, doRename, operateStep, type]); // onPressEnter中会调用doRename，因此需要加入依赖项。

  const ContentView = () => {
    if (operateStep === OPERATE_CONTENT_DATA.showRenameStep) { return RenameContent; }
    return OperateList;
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      visible={operateStep !== OPERATE_CONTENT_DATA.hiddenStep}
      onVisibleChange={onVisibleChange}
      overlayClassName={cx(
        'catalog-operate-container',
        'dk-ant-popover__reset',
      )}
      content={ContentView}
      destroyTooltipOnHide={{ keepParent: false }}
      // getPopupContainer={getPopupContainer}
    >
      {children}
    </Popover>
  );
}

export default OperatePopover;
