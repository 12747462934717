/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-08-09 11:03:04
 * @LastEditTime: 2024-04-18 12:08:58
 * @Description: 存放所有不需要侧边栏以及需要单独定义header的页面
 * @FilePath: /knowledgeforge/src/routes/router-global.js
 *
 */

import store from '@/model';
import React, { Suspense } from 'react';
import SkeletonPage from '@/components/SkeletonPage';

const TeamInvite = React.lazy(() => import(/* webpackChunkName: "teamInv" */ '@/pages/cooper/TeamInvite'));
const CooperSharesFolder = React.lazy(() => import(/* webpackChunkName: "shaDown" */ '@/pages/cooper/Share/CooperShareDownload/index'));
const SendFileDc = React.lazy(() => import(/* webpackChunkName: "sendD" */ '@/pages/cooper/cooper-sendfile-dc'));
export const TenantLoginPage = React.lazy(() => import(/* webpackChunkName: "tenLog" */ '@/pages/cooper/TenantLogin'));
const SpaceInvalid = React.lazy(() => import(/* webpackChunkName: "spaInv" */ '@/pages/cooper/SpaceInvalid'));
const Login = React.lazy(() => import(/* webpackChunkName: "log" */ '@/pages/cooper/Login'));
const DiNOte = React.lazy(() => import(/* webpackChunkName: "dNote" */ '@/pages/cooper/DiNote'));
const SpaceForbid = React.lazy(() => import(/* webpackChunkName: "spaFor" */ '@/pages/cooper/SpaceForbid'));


let route = [
  {
    path: '/teams/invite/:inviteId',
    name: '团队添加成员',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><TeamInvite /></Suspense>,
    key: 'teamSpace',
  },
  {
    path: '/team-file/team-invalid',
    name: '空间失效或链接错误',
    exact: true,
    key: 'teamSpace',
    element: <Suspense fallback={<SkeletonPage />}><SpaceInvalid /></Suspense>,
  },
  {
    path: '/team-file/team-forbid/:type',
    name: '空间不允许访问',
    exact: true,
    key: 'teamSpace',
    element: <Suspense fallback={<SkeletonPage />}><SpaceForbid /></Suspense>,
  },
  {
    path: 'shares/:shareid',
    name: '分享文件夹',
    exact: true,
    key: 'share',
    element: <Suspense fallback={<SkeletonPage />}><CooperSharesFolder /></Suspense>,
  },
  {
    path: '/send-file',
    name: '发送文件',
    exact: true,
    key: 'sendFile',
    element: <Suspense fallback={<SkeletonPage />}><SendFileDc/></Suspense>,
  },
  {
    path: '/tenant',
    name: '租户切换',
    key: 'global',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><TenantLoginPage /></Suspense>,
  },
  {
    path: '/note/*',
    name: '氢笔记',
    key: 'note',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><DiNOte /></Suspense>,
  },
  {
    path: '/loginPage',
    name: () => '登陆',
    key: 'global',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><Login /></Suspense>,
  },
];

let globalRoute = []


// eslint-disable-next-line import/prefer-default-export
export const getGlobalRoute = () => {
  const { IsExternalTenant, tenantConfig } = store.getState().GlobalData
  if (IsExternalTenant) {
    globalRoute = route.filter((item) => tenantConfig.find((s) => s.configName === item.key)?.enable || item.key === 'global')
  } else {
    globalRoute = route
  }
  return { globalRoute };
};
