/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-15 16:41:43
 * @LastEditTime: 2023-10-24 15:05:12
 * @Description: 表格展示
 * @FilePath: /knowledgeforge/src/components/RecentActivity/file-table/index.js
 *
 */
import classBind from 'classnames/bind';
import { useSelector } from 'react-redux';
import { getLocale, intl } from 'di18n-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Loading from '../../SpinRender';
import { FILETYPE } from '@/components/CooperFilesList/FoldTree/utils';
import handleFileClick from '@/components/CooperOperation/HandleFileClick';
import NoMore from '@/components/NoMore';
import OperateMenu from '@/components/OperateMenu';
import { updateQuicks } from '@/components/QuickAccess/index';
import SpinRender from '@/components/SpinRender';
import FileEllipsis from '@/components/common/FileEllipsis';
import ImageEnlarger from '@/components/common/ImageEnlarger';
import { FileType } from '@/constants/cooper';
import { FlowChart, RECENT_LIST } from '@/constants/cooperConstants';
import { setImgUrl } from '@/utils/cooperutils';
import { formatRecentTime, isDC, getUserUidFromCookie } from '@/utils/index';
import { Popover, Tooltip } from 'antd';
import CooperEmptyTip from '../../CooperEmptyTip/index';
import goToPath from '../goToPath';
// import { entryEnhance } from '@/utils/entryEnhance';
// import { apolloSwitch, CooperPOCreate } from '@/utils/ab';
import styles from './style.module.less';
import { RECENT_LIST_OPT } from '@/components/OperateMenu/constant';
import SortIcon from '@/components/CooperFilesList/SortIcon/index';
import SearchFileType from '../searchType/index'
import SearchFileOwner from '../searchOwner/index'


// const CheckboxGroup = Checkbox.Group

const cx = classBind.bind(styles);

// coo_doc,coo_sheet,coo_ppt,coo_file,dk_page
const FilterResourceTypeRecentValue = [
  {
    text: () => intl.t('全部类型'),
    value: 'all',
  },
  {
    text: () => intl.t('文档'),
    value: 'coo_doc',
  },
  {
    text: () => intl.t('表格'),
    value: 'coo_sheet',
  },
  {
    text: () => intl.t('流程图'),
    value: 'flow',
  },
  {
    text: () => intl.t('思维导图'),
    value: 'mind',
  },
  {
    text: () => intl.t('幻灯片'),
    value: 'coo_ppt',
  },
  {
    text: () => intl.t('文件'),
    value: 'coo_file',
  },
  {
    text: () => intl.t('Wiki页面'),
    value: 'wiki',
  },
];

// coo_doc,coo_sheet,coo_ppt,dk_page
const FilterResourceTypeEditeValue = [
  {
    text: () => intl.t('全部类型'),
    value: 'all',
  },
  {
    text: () => intl.t('文档'),
    value: 'coo_doc',
  },
  {
    text: () => intl.t('表格'),
    value: 'coo_sheet',
  },
  {
    text: () => intl.t('流程图'),
    value: 'flow',
  },
  {
    text: () => intl.t('思维导图'),
    value: 'mind',
  },
  {
    text: () => intl.t('幻灯片'),
    value: 'coo_ppt',
  },
  {
    text: () => intl.t('Wiki页面'),
    value: 'wiki',
  },
];

const filterInfoRecentValue = {
  initFilter: ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file', 'flow', 'mind'],
  initTenantFilter: ['all'],
  filterAll: ['coo_doc', 'coo_sheet', 'coo_ppt', 'coo_file', 'wiki', 'flow', 'mind'],
  filterTenantAll: ['coo_doc', 'coo_sheet', 'coo_file'],
}

const filterInfoEditValue = {
  initFilter: ['coo_doc', 'coo_sheet', 'coo_ppt', 'flow', 'mind'],
  initTenantFilter: ['all'],
  filterAll: ['coo_doc', 'coo_sheet', 'coo_ppt', 'wiki', 'flow', 'mind'],
  filterTenantAll: ['coo_doc', 'coo_sheet'],
}

const isRegularFile = (type) => {
  // 可下载类型增多
  const downloadType = [FileType.FILE, FileType.DIR, FileType.COOPER_DOC, FileType.COOPER_SHEET, FileType.COOPER_SLICE, FileType.COOPER_ANONYMOUS_DOC, FileType.SHIMO2_WORD, FileType.SHIMO2_EXCEL, FileType.SHIMO2_PPT, FileType.MINDMAP, FileType.DIAGRAMING]
  return downloadType.includes(type)
};

// 列表不返回已经删除的文件,实际可以不进行判断
const handlePathClick = (f, navigate) => {
  const params = {
    resourceTypeStr: f.resourceTypeStr,
    teamId: f.teamId,
    shareLink: f.shareLink,
    shareId: f.shareId,
    id: f.parentId,
    deleted: f.deleted,
  };
  goToPath(params, '', navigate);
};

const isDk = (type) => {
  return type === FileType.DK_FILE || type === FileType.DK_PAGE;
};

function operateConfig(type) {
  if (isDk(type)) {
    return {
      copyLink: true,
      duplicateOperate: false,
      multiSelectOperate: false,
      starOperate: false,
      moveOperate: false,
      copyOperate: false,
      deleteOperate: false,
      downloadOperate: true,
      renameOperate: false,
      shareOperate: false,
    };
  }
  if (type === FileType.WIKI_PAGE) {
    return {
      copyLink: true,
      duplicateOperate: false,
      multiSelectOperate: false,
      starOperate: false,
      moveOperate: false,
      copyOperate: false,
      deleteOperate: false,
      downloadOperate: true,
      renameOperate: false,
      shareOperate: false,
      quickAccessOperate: false,
    };
  }
  return {
    downloadOperate: isRegularFile(type),
    duplicateOperate: false,
    starOperate: false,
    multiSelectOperate: false,
    // deleteOperate: type !== FileType.FLOWCHART,
    deleteOperate: false,
  };
}

function FileTables(props) {
  const {
    rowKey,
    filteredInfo,
    getWholePath,
    updateCurrentList,
    doFilters,
    dataSource,
    loading,
    loadEnd,
  } = props;
  const [ownerVisible, setOwnerVisible] = useState(false);
  // const [showPO, setShowPO] = useState(false);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  const [filterMsg, setFilterMsg] = useState({ ...filteredInfo });
  const [, setRefresh] = useState(0);
  const navigate = useNavigate();
  const isRecentTab = rowKey == 1;
  let len = dataSource.length || 0;
  const curLocale = getLocale();

  // 重复赋值，禁用
  useEffect(() => {
    setFilterMsg(filteredInfo);
  }, [filteredInfo]);

  function updateFilteredMsg(key, value) {
    let temp = filterMsg;
    setFilterMsg(temp);
    temp[key] = key === 'type' ? value.toString() : value;
    return temp;
  }

  // 处理像option这样的数据
  const optionEnhance = (options) => {
    // 判断是否为多租户，否直接返回入参。是经过处理
    if (IsExternalTenant) {
      const exceptList = ['coo_ppt', 'wiki', 'flow', 'mind'];
      return options.filter((v) => !exceptList.includes(v.value));
    }
    return options;
  };

  const getSorterStatus = (data, orderAsc) => {
    return data.timeOrder === orderAsc
  }
  const toggleSort = (type, orderAsc) => {
    let filteredInfoNew = updateFilteredMsg(type, orderAsc);
    doFilters(filteredInfoNew, type);
  };
  const getWholFileAdress = (item) => {
    const { spaceType, fileDir, resourceTypeStr, shareType } = item;
    if (fileDir === '--') {
      return fileDir;
    }
    if (spaceType === 'TEAM_SPACE' && !shareType) {
      return `${intl.t('团队空间')}/${fileDir}`;
    }
    if (isDk(resourceTypeStr)) {
      return `${intl.t('知识库')}/${fileDir}`;
    }
    if (
      spaceType === 'PERSONAL_SPACE'
      && fileDir !== intl.t('个人空间')
      && !shareType
    ) {
      return `${intl.t('个人空间')}/${fileDir}`;
    }
    if (spaceType === 'WIKI_SPACE') {
      return `${intl.t('Wiki')}/${fileDir}`;
    }
    return fileDir;
  };

  return (
    <div className={cx('cooper-list-content')}>
      <Fragment>
        <div className={cx('folder-tree')}>
          <div className={cx('tb-header', { 'dc-tb-header': isDC() })}>
            <div className={cx('tb-header-div')}>
              <span
                className={cx('file-name')}>
                <SearchFileType
                  filterMsg= { filterMsg.type.split(',') || [] }
                  // eslint-disable-next-line max-len
                  valueMap = { optionEnhance(isRecentTab ? FilterResourceTypeRecentValue : FilterResourceTypeEditeValue) }
                  filterInfo={isRecentTab ? filterInfoRecentValue : filterInfoEditValue }
                  updateFilteredMsg={updateFilteredMsg}
                  doFilters={doFilters}
                />
              </span>
              <span className={cx('file-owner')}>
                <SearchFileOwner
                  filterMsg= { filterMsg.ownerType || [] }
                  updateFilteredMsg={updateFilteredMsg}
                  doFilters={doFilters}
                />
              </span>
              <span className={cx('file-address')}>{intl.t('文件位置')}</span>
              <span
                className={cx('file-time', 'file-time-sort')}
                onClick={() => {
                  toggleSort('timeOrder', filterMsg.timeOrder === '1' ? '0' : '1')
                }}
              >
                { isRecentTab ? intl.t('浏览时间') : intl.t('编辑时间') }
                <SortIcon
                  iconUp={getSorterStatus(filterMsg, '0')}
                  iconDown={getSorterStatus(filterMsg, '1')}
                />
              </span>
              {isRecentTab && (
                <span className={cx('file-operate')}>{intl.t('操作')}</span>
              )}
            </div>
          </div>
          <div className={cx('tb-body', { 'dc-tb-body': isDC() })}>
            {dataSource
              && dataSource.map((item, index) => {
                return (
                  <li
                    className={cx('tb-body-row')}
                    key={index}>
                    <span
                      className={cx('file-name')}
                      onClick={() => {
                        handleFileClick({
                          fileTypeStr: item.resourceTypeStr,
                          resourceId: item.resourceId,
                          resourceName: item.resourceName,
                          knowledgeId: item.teamId,
                          dkShareType: item.shareType,
                          pageId: item.resourceId,
                          shareId: item.shareId,
                          shareLink: item.shareLink,
                          markedQuickVisit: item.markQuickVisit,
                          teamId: item.teamId,
                          quickId: item.quickId,
                          parentId: item.parentId,
                          spaceId: item.spaceId,
                          relationTypeTags: item.relationTypeTags,
                        });

                        if (isRecentTab) {
                          window.__OmegaEvent('ep_home_recent_visit_ck', '', {
                            resourceid: item.resourceId,
                            resource_id: item.resourceId,
                            position: index,
                            platform: 'new',
                            type: FILETYPE[item.resourceType],
                          });
                        } else {
                          // TODO:
                          window.__OmegaEvent('ep_home_edited_visit_ck', '', {
                            resourceid: item.resourceId,
                            resource_id: item.resourceId,
                            position: index,
                            platform: 'new',
                            type: FILETYPE[item.resourceType],
                          });
                        }
                      }}
                    >
                      <ImageEnlarger
                        src={setImgUrl(item)}
                        isTiny={!!item.tiny}
                        mimeType={item.mime_type || item.mimeType}
                        resourceType={item.resourceTypeStr}
                      />
                      <FileEllipsis
                        value={item.resourceName}
                        record={item}
                        hasVisitorTag={
                          isDk(item.resourceTypeStr) && item.shareLink !== ''
                        }
                      />
                    </span>
                    {/* <div className={cx('file-resizer')} style={{ height: 50 }}>
                     <div className={cx('resizer-line')} style={{ display: isDragging ? 'block' : 'none' }} />
                    </div> */}
                    <span className={cx('file-owner')}>
                      <div
                        className={cx(
                          'file-content-box',
                          'file-owner-content-box',
                        )}
                      >
                        {curLocale === 'zh-CN'
                          ? item?.userInfo?.user_cn || '--'
                          : item?.userInfo?.user || '--'}
                      </div>
                    </span>
                    <span className={cx('file-address')}>
                      <Tooltip
                        title={item.globalDir || '--'}
                        placement="topLeft"
                      >
                        <div
                          className={cx('file-content-box')}
                          onMouseOver={() => {
                            if (!item.globalDir) {
                              getWholePath(item, index);
                              setRefresh(Date.now());
                            }
                          }}
                          onClick={() => {
                            handlePathClick(item, navigate);
                          }}
                        >
                          {getWholFileAdress(item)}
                        </div>
                      </Tooltip>
                    </span>
                    <span className={cx('file-time')}>
                      <div className={cx('file-content-box')}>
                        {isRecentTab
                          ? formatRecentTime(item.visitOn)
                          : formatRecentTime(item.operationOn)}
                      </div>
                    </span>
                    {isRecentTab && (
                      <span className={cx('file-operate')}>
                        <OperateMenu
                          file={item}
                          doneCallback={[updateCurrentList, updateQuicks]}
                          config={operateConfig(item.resourceTypeStr)}
                          originFileType={RECENT_LIST}
                          isFlowChart={
                            item.resourceType === FlowChart
                            && item.mimeType === 9
                          }
                          key={Date.now()}
                          isTeam={item.spaceType === 'TEAM_SPACE'}
                          location={RECENT_LIST_OPT}
                        />
                      </span>
                    )}
                  </li>
                );
              })}
          </div>
        </div>
        {!loading && len === 0 ? (
          <CooperEmptyTip title={intl.t('暂无数据')} />
        ) : (
          <div className={cx('cooper-recent-table-loading')}>
            {loadEnd && !loading && <NoMore />}
            <SpinRender loading={loading} />
          </div>
        )}
      </Fragment>
    </div>
  );
}

export default FileTables;
