import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { intl } from 'di18n-react';
import { getOfficialAccount } from '@/service/knowledge/iportal';
import classBind from 'classnames/bind';
import styles from '../style.module.less';

const cx = classBind.bind(styles);

const { Option } = Select;

function OfficialAccountSelect({ ldap, value, onSelect }) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const requestData = async () => {
    const res = await getOfficialAccount({ ldap })
    setOptions(res);
    setLoading(false);
  };

  useEffect(() => {
    requestData();
  }, []);

  return (
    <Select
      className={cx('send-to-iportal-modal-select')}
      dropdownClassName={cx('send-to-iportal-modal-select-option')}
      placeholder={intl.t('请选择订阅号')}
      suffixIcon={(<i className={cx('send-to-iportal-modal-select-suffix-icon', 'dk-iconfont', 'dk-icon-jiantouxia')}/>)}
      optionLabelProp="label"
      value={value}
      onChange={onSelect}
      notFoundContent={(
        <div className={cx('send-to-iportal-modal-select-empty')}>{intl.t(loading ? '加载中...' : '无担任管理员的订阅号')}</div>
      )}
      virtual={false}
    >
      {
        options.map((item) => (
          <Option key={item.ldap} value={item.ldap} label={item.name}>
            <div className={cx('send-to-iportal-modal-select-option-item')}>
              <img className={cx('send-to-iportal-modal-select-option-avatar')} src={item.jobCardAvatar} alt=''/>
              <div className={cx('send-to-iportal-modal-select-option-content')}>
                <div className={cx('send-to-iportal-modal-select-option-content-title')}>{item.name}</div>
                <div className={cx('send-to-iportal-modal-select-option-content-desc')}>{item.signature}</div>
              </div>
              <i className={cx('send-to-iportal-modal-select-option-selected-icon', 'dk-iconfont', 'dk-icon-gou')}/>
            </div>
          </Option>
        ))
      }
    </Select>
  );
}

export default OfficialAccountSelect;
