import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLayoutEffect, useMemo, useState } from 'react';
import IportalIcon from '@/assets/icon/iportal.svg';
import styles from './style.module.less';
import OfficialAccountSelect from '@/components/SendToIportalModal/components/OfficialAccountSelect';
import CommunitySpaceSelect from '@/components/SendToIportalModal/components/CommunitySpaceSelect';
import { getUserNameFromCookie } from '@/utils';
import { saveTalkDraft } from '@/service/knowledge/iportal';

const cx = classBind.bind(styles);

export default function SendToIportalModal(props) {
  const { closeModal, title, richText, textLength } = props;
  const [loading, setLoading] = useState(false);
  const [isSelf, setIsSelf] = useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [officialAccount, setOfficialAccount] = useState(null);
  const [communitySpace, setCommunitySpace] = useState(null);
  const [type, setType] = useState(null);
  const username = getUserNameFromCookie();

  const handleCancel = () => {
    closeModal();
  }

  const handleSelectOfficialAccount = (data) => {
    setOfficialAccount(data);
  }

  const handleSelectCommunitySpace = (data) => {
    setCommunitySpace(data);
  }

  const handleSelectType = (data) => {
    setType(data);
  }

  useLayoutEffect(() => {
    setCommunitySpace(null);
  }, [type, isSelf, officialAccount])

  const toggleAgree = () => {
    setIsAgree((val) => !val);
  }

  const ldap = useMemo(() => {
    return isSelf ? username : officialAccount;
  }, [isSelf, officialAccount]);

  const TypeList = useMemo(() => {
    const result = [
      {
        name: 'Way社区',
        value: 'way_article',
      },
      {
        name: '交流',
        value: 'talk',
      },
      {
        name: '圈子',
        value: 'talk',
      },
    ];

    if (!isSelf) {
      result.unshift({
        name: '资讯',
        value: 'information',
      })
    }

    return result;
  }, [isSelf]);

  const isSpaceType = useMemo(() => {
    return type?.name === '圈子';
  }, [type]);

  const isValid = useMemo(() => {
    return isAgree && (isSelf || officialAccount) && (!isSpaceType || communitySpace);
  }, [isSelf, officialAccount, communitySpace, isSpaceType, isAgree])

  const handleOk = () => {
    setLoading(true);
    const form = {
      title,
      richText,
      ldap,
      currentUser: username,
      editorType: 'ueditor',
      textLength,
      type: type.value,
    };

    if (type.name === '圈子') {
      const [spaceId, moduleId] = communitySpace.split('_');
      form.spaceId = spaceId
      form.moduleId = moduleId
    }

    saveTalkDraft(form)
      .then((res) => {
        if (res.success) {
          closeModal();
          message.success(intl.t('提交成功'));
          window.open(res.data, '_blank');
        } else {
          message.error(intl.t(res.errMessage || res.message));
          setLoading(false);
        }
      })
  };

  useLayoutEffect(() => {
    setType(TypeList[0]);
    setOfficialAccount(null);
  }, [isSelf])

  return (
    <Modal
      title={null}
      footer={null}
      centered={true}
      visible={true}
      closeIcon={(
        <span className={cx('send-to-iportal-modal-close-btn')}>
          <i className={cx('send-to-iportal-modal-close-icon', 'dk-iconfont dk-icon-guanbi1')}/>
        </span>
      )}
      onOk={handleOk}
      onCancel={handleCancel}
      className={cx('send-to-iportal-modal')}
    >
      <div className={cx('send-to-iportal-modal-content')}>
        <div className={cx('send-to-iportal-modal-header')}>
          <img className={cx('send-to-iportal-modal-header-logo')} src={IportalIcon} alt=''/>
          <span className={cx('send-to-iportal-modal-header-text')}>
            {intl.t('发布至桔子堆')}
          </span>
        </div>
        <div className={cx('send-to-iportal-modal-main')}>
          <section className={cx('send-to-iportal-modal-main-section')}>
            <div className={cx('send-to-iportal-modal-main-title')}>{intl.t('选择作者身份')}</div>
            <div className={cx('send-to-iportal-modal-main-content')}>
              <div className={cx('send-to-iportal-modal-main-content-left', {'is-active': isSelf})} onClick={() => setIsSelf(true)}>
                <i className={cx('send-to-iportal-modal-selected-icon', 'dk-iconfont', isSelf ? 'dk-icon-danxuanyixuan' : 'dk-icon-danxuanweixuan')}/>
                <div className={cx('send-to-iportal-modal-main-content-summary')}>
                  <div className={cx('send-to-iportal-modal-main-content-summary-title')}>{intl.t('个人的')}</div>
                  <div className={cx('send-to-iportal-modal-main-content-summary-desc')}>{intl.t('作者为自己，不显示其他协作者')}</div>
                </div>
              </div>
              <div className={cx('send-to-iportal-modal-main-content-right', {'is-active': !isSelf})} onClick={() => setIsSelf(false)}>
                <i className={cx('send-to-iportal-modal-selected-icon', 'dk-iconfont', isSelf ? 'dk-icon-danxuanweixuan' : 'dk-icon-danxuanyixuan')}/>
                <div className={cx('send-to-iportal-modal-main-content-summary')}>
                  <div className={cx('send-to-iportal-modal-main-content-summary-title')}>{intl.t('订阅号')}</div>
                  <div className={cx('send-to-iportal-modal-main-content-summary-desc')}>{intl.t('以订阅号账号发布')}</div>
                </div>
              </div>
            </div>
          </section>

          {
            isSelf || (
              <section className={cx('send-to-iportal-modal-main-section')}>
                <div className={cx('send-to-iportal-modal-main-title')}>{intl.t('选择订阅号')}</div>
                <div className={cx('send-to-iportal-modal-main-content')}>
                  <OfficialAccountSelect ldap={ldap} value={officialAccount} onSelect={handleSelectOfficialAccount}/>
                </div>
              </section>
            )
          }

          <section className={cx('send-to-iportal-modal-main-section')}>
            <div className={cx('send-to-iportal-modal-main-title')}>{intl.t('选择发布渠道')}</div>
            <div className={cx('send-to-iportal-modal-main-content')}>
              <div className={cx('send-to-iportal-modal-radio-group')}>
                {
                  TypeList.map((item) => (
                    <span key={item.name} className={cx('send-to-iportal-modal-radio-item', {'is-active': item.name === type?.name})} onClick={() => handleSelectType(item)}>
                      <i className={cx('send-to-iportal-modal-selected-icon', 'dk-iconfont', item.name === type?.name ? 'dk-icon-danxuanyixuan' : 'dk-icon-danxuanweixuan')}/>
                      {intl.t(item.name)}
                    </span>
                  ))
                }
              </div>
            </div>
          </section>

          {
            isSpaceType && (
              <section className={cx('send-to-iportal-modal-main-section')}>
                <div className={cx('send-to-iportal-modal-main-title')}>{intl.t('选择圈子')}</div>
                <div className={cx('send-to-iportal-modal-main-content')}>
                  <CommunitySpaceSelect
                    disabled={!isSelf && !officialAccount}
                    ldap={ldap}
                    value={communitySpace}
                    onSelect={handleSelectCommunitySpace}
                  />
                </div>
              </section>
            )
          }

          <section className={cx('send-to-iportal-modal-main-section')}>
            <div className={cx('send-to-iportal-modal-main-panel')}>
              <div className={cx('send-to-iportal-modal-main-notice', {'is-active': isAgree})} onClick={toggleAgree}>
                <i className={cx('send-to-iportal-modal-main-notice-icon', 'dk-iconfont', isAgree ? 'dk-icon-duoxuanyixuan' : 'dk-icon-duoxuanweixuan')}/>
                <span>{intl.t('我已知晓以下注意事项')}</span>
              </div>
              <ol className={cx('send-to-iportal-modal-main-notice-list')}>
                <li className={cx('send-to-iportal-modal-main-notice-list-item')}>{intl.t('桔子堆长文内容单篇字数上限为7万字，超过字数限制无法对外发布。')}</li>
                <li className={cx('send-to-iportal-modal-main-notice-list-item')}>{intl.t('同步至桔子堆的内容以文档最近一次发布版本为准，且不会同步更新。')}</li>
                <li className={cx('send-to-iportal-modal-main-notice-list-item')}>{intl.t('以下内容同步至桔子堆后，样式可能存在差异，请仔细校验，确认无误后再发布：表格、超链接卡片、任务列表、折叠块、代码块、包含页面。')}</li>
                <li className={cx('send-to-iportal-modal-main-notice-list-item')}>{intl.t('以下内容无法直接同步至桔子堆，请自行转为图片格式，以免内容丢失：流程图、思维导图。')}</li>
              </ol>
            </div>
          </section>
        </div>
        <div className={cx('send-to-iportal-modal-footer')}>
          <button className={cx('send-to-iportal-modal-cancel')} onClick={handleCancel}>
            {intl.t('取消')}
          </button>
          <button className={cx('send-to-iportal-modal-confirm', {'is-loading': loading})} disabled={!isValid} onClick={handleOk}>
            {loading && <LoadingOutlined className={cx('send-to-iportal-modal-confirm-icon')}/>}
            {intl.t(loading ? '提交中...' : '提交并前往桔子堆发布')}
          </button>
        </div>
      </div>
    </Modal>
  );
}
