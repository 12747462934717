import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { intl } from 'di18n-react';
import { getSpaceList } from '@/service/knowledge/iportal';
import classBind from 'classnames/bind';
import styles from '../style.module.less';

const cx = classBind.bind(styles);

function CommunitySpaceSelect({ disabled, ldap, value, onSelect }) {
  const [options, setOptions] = useState([]);

  const requestData = async () => {
    const res = await getSpaceList({ ldap });
    setOptions(res);
  };

  useEffect(() => {
    requestData();
  }, [ldap]);

  return (
    <Select
      key={ldap}
      className={cx('send-to-iportal-modal-select')}
      dropdownClassName={cx('send-to-iportal-modal-select-option')}
      placeholder={intl.t('请选择圈子')}
      suffixIcon={(<i className={cx('send-to-iportal-modal-select-suffix-icon', 'dk-iconfont', 'dk-icon-jiantouxia')}/>)}
      optionLabelProp="label"
      disabled={disabled}
      value={value}
      onChange={onSelect}
      notFoundContent={(
        <div className={cx('send-to-iportal-modal-select-empty')}>{intl.t('暂未加入任何圈子')}</div>
      )}
      virtual={false}
    >
      {
        options.map((item) => (
          <Option key={item.moduleId} value={`${item.id}_${item.moduleId}`} label={item.spaceName}>
            <div className={cx('send-to-iportal-modal-select-option-item')}>
              <img className={cx('send-to-iportal-modal-select-option-avatar')} src={item.headUrl} alt=''/>
              <div className={cx('send-to-iportal-modal-select-option-content')}>
                <div className={cx('send-to-iportal-modal-select-option-content-title')}>{item.spaceName}</div>
                <div className={cx('send-to-iportal-modal-select-option-content-desc')}>{item.spaceDesc}</div>
              </div>
              <i className={cx('send-to-iportal-modal-select-option-selected-icon', 'dk-iconfont', 'dk-icon-gou')}/>
            </div>
          </Option>
        ))
      }
    </Select>
  );
}

export default CommunitySpaceSelect;
