import { intl } from 'di18n-react';
/* eslint-disable */
import {
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { Radio } from "antd";
import UserItem from "@/components/common/ListOptions/UserItem";
import TagItem from "@/components/common/ListOptions/TagItem";
import classBind from "classnames/bind";
import useUpdate from "@/hooks/useUpdate";
import { searchTags } from "@/service/knowledge/search";
import { getUserData } from "@/service/knowledge/global";
import SelectInput from "@/components/common/SelectInput";
import { spaceTeam } from "@/assets/icon/fileIcon";
import { scopeMap } from "@/constants/search";
import { getUserNameFromCookie, highlight } from "@/utils";
import HelpLink from "@/components/HelpLink";
import SelectAnyOption from "@/components/common/SelectAnyOption";
import MultiChoose from "./MultiChoose";
import styles from "./style.module.less";
import NormalOptions from "@/components/common/SelectAnyOption/NormalOptions";
import { isDiDiTenant } from '@/utils/entryEnhance'
import { useSelector } from 'react-redux';
const cx = classBind.bind(styles);
import store from '@/model';
const configMap = () => {
  return {
    "1d": intl.t("一天内"),
    "7d": intl.t("一周内"),
    "30d": intl.t("一月内"),
    "180d": intl.t("半年内"),
    "365d": intl.t("一年内"),
  };
};

const getSearchConfig = () => {
  return {
    time: [
      {
        label: intl.t("一天内"),
        value: "1d",
      },
      {
        label: intl.t("一周内"),
        value: "7d",
      },
      {
        label: intl.t("一月内"),
        value: "30d",
      },
      {
        label: intl.t("半年内"),
        value: "180d",
      },
      {
        label: intl.t("一年内"),
        value: "365d",
      },
    ],
    fileType: [
      {
        label: "word",
        value: "1",
      },
      {
        label: "excel",
        value: "2",
      },
      {
        label: "ppt",
        value: "3",
      },
      {
        label: "pdf",
        value: "4",
      },
      {
        label: intl.t("视频"),
        value: "5",
      },
      {
        label: intl.t("图片"),
        value: "6",
      },
      {
        label: intl.t("其他"),
        value: "99",
      },
    ],
  };
};

const Filter = (props) => {
  
  const ldap = getUserNameFromCookie();

  const { tenantId } = useSelector((state) => state.GlobalData);
  const { selectionMap, filter = {}, searchScopeName, searchScopeId } = props;

  const {
    locationIds = [],
    moduleTypes = [],
    owners = [],
    tagIds = [],
    time = "",
    fileType = "0",
    resourceOwners = []
  } = filter;

  const LocationDeFault = useMemo(() => {
    if (searchScopeId === undefined || searchScopeId === undefined) return;
    let defaultOBj = [];
    if (isDiDiTenant()) {
      defaultOBj = [
        {
          value: scopeMap.PERSONAL,
          label: intl.t("我的个人空间"),
          isPreset: true,
          checked: searchScopeId === scopeMap.PERSONAL,
        },
        {
          value: scopeMap.TEAMS,
          label: intl.t("全部团队空间"),
          isPreset: true,
          checked: searchScopeId === scopeMap.TEAMS,
        },
        {
          value: scopeMap.DKS,
          label: intl.t("全部知识库"),
          isPreset: true,
          checked: searchScopeId === scopeMap.DKS,
        },
      ];
    } else {
      defaultOBj = [
        {
          value: scopeMap.TEAMS,
          label: intl.t("全部团队空间"),
          isPreset: true,
          checked: searchScopeId === scopeMap.TEAMS,
        },
      ];
    }
    if (
      [
        scopeMap.PERSONAL,
        scopeMap.TEAMS,
        scopeMap.DKS,
        scopeMap.STAR,
        undefined,
        "",
      ].includes(searchScopeId)
    ) {
      return defaultOBj;
    }
    return [
      ...defaultOBj,
      {
        value: searchScopeId,
        label: searchScopeName,
        isPreset: false,
        checked: true,
        iconImage: spaceTeam,
      },
    ];
  }, [searchScopeId, searchScopeName]);

  const ModuleDeFault = useMemo(() => {
    if (searchScopeId === undefined) return;
    return [
      {
        value: scopeMap.STAR,
        label: intl.t("收藏"),
        checked: searchScopeId === scopeMap.STAR,
        isPreset: true,
      },
    ];
  }, [searchScopeId]);

  const OwnerDeFault = () => {
    return [
      {
        value: `${ldap}@${tenantId}`,
        label: intl.t("归我所有"),
        checked: false,
        isPreset: true, // 预设的不允许删除
      },
    ];
  };

  const [displayObj, setDisplayObj] = useState({}); // 维护每个多选操作的展示项

  useEffect(() => {
    if (searchScopeId === undefined || searchScopeName === undefined) return;
    if (Boolean(searchScopeId) == Boolean(searchScopeName)) {
      setDisplayObj({
        locationIds: LocationDeFault,
        owners: OwnerDeFault(),
        tagIds: [],
        moduleTypes: ModuleDeFault,
        time,
        fileType,
      });
    }
  }, [searchScopeId, searchScopeName]);

  useEffect(() => {
    if (JSON.stringify(displayObj) === "{}") return;
    let newValue = {
      locationIds: [],
      owners: [],
      tagIds: [],
      moduleTypes: [],
      time: "",
      fileType: "",
      resourceOwners: [],
    };
    Object.keys(displayObj).forEach((keys) => {
      if (["locationIds", "owners", "tagIds", "moduleTypes"].includes(keys)) {
        displayObj?.[keys].forEach((i) => {
          if(keys === 'owners') {
            if (i.checked) newValue['resourceOwners'].push({
              ldap: i.value,
              tenantId: i?.tenantId || store.getState().GlobalData.tenantId,
            });
          }
          if (i.checked) newValue[keys].push(i.value);
        });
      } else {
        newValue[keys] = displayObj[keys];
      }
    });
    props.onChange(newValue);
  }, [displayObj]);

  const canFilter = (value) => {
    return selectionMap?.includes(value);
  };

  const updateDisplayObj = (v, t) => {
    setDisplayObj({ ...displayObj, [t]: v });
  };

  // 更改原始展示的可选项 operateObj：要更改的某个元素  displayNameKey：要更改的原始可选项的key
  const changeDisObj = (operateObj, displayNameKey) => {
    if (!operateObj.value || (typeof operateObj?.value === 'string' && operateObj?.value?.includes('undefined'))) return;
    const changeOriginObj = displayObj[displayNameKey];
    const changeObj = changeOriginObj.filter((item) => {
      if (item.value == operateObj.value) return item; // 有string 和number两种类型
    });
    if (changeObj.length === 0) {
      updateDisplayObj(
        [
          ...changeOriginObj,
          {
            value: operateObj?.value,
            label: operateObj?.label?.replace(/§§/g, ""),
            checked: true,
            iconImage: operateObj?.iconImage,
            tenantId: operateObj?.tenantId,
          },
        ],
        displayNameKey
      );
      return;
    }
    let res = [...changeOriginObj];
    res.forEach((item) => {
      if (item.value == operateObj.value) {
        item.checked = operateObj.checked;
      }
    });
    updateDisplayObj(res, displayNameKey);
  };

  // 修改选项
  const changeChooseList = (vArray, displayNameKey) => {
    const changeOriginObj = displayObj[displayNameKey];

    const changeItem = changeOriginObj.filter((item) => {
      return item.checked !== vArray.includes(item.value);
    });
    changeDisObj(changeItem[0], displayNameKey);

    switch (displayNameKey) {
      case "locationIds":
        window.__OmegaEvent(
          "ep_search_advanced_filter_location_ck",
          "高级搜索-筛选-位置",
          {
            source: vArray,
          }
        );
        break;
      case "moduleTypes":
        window.__OmegaEvent(
          "ep_search_advanced_filter_module_ck",
          "高级搜索-筛选-模块",
          {
            source: vArray,
          }
        );

        break;
      case "owners":
        window.__OmegaEvent(
          "ep_search_advanced_filter_owner_ck",
          "高级搜索-筛选-所有者",
          {
            source: vArray,
          }
        );

        break;
      case "tagIds":
        break;

      default:
        break;
    }
  };

  //删除选项
  const removeChooseItem = (k, displayNameKey) => {
    const changeOriginObj = displayObj[displayNameKey];

    const newObj = changeOriginObj.filter((item) => {
      return item.value !== k;
    });
    updateDisplayObj(newObj, displayNameKey);
  };

  return (
    <div className={cx("search-page-filter", "os-scrollbar")}>
      <div
        className={cx("filter-item", {
          hidden: !canFilter("position"),
        })}
      >
        <div className={cx("filter-item-title")}>{intl.t("位置")}</div>
        <MultiChoose
          defaultOptions={displayObj.locationIds ?? []}
          changeChooseList={(vArray) => changeChooseList(vArray, "locationIds")}
          removeChooseItem={(value) => removeChooseItem(value, "locationIds")}
        >
          <SelectAnyOption
            placeholder={
              isDiDiTenant()
                ? intl.t("输入团队空间/知识库名称")
                : intl.t("输入团队空间名称")
            }
            onChoose={(chooseObj) => {
              changeDisObj(
                {
                  value: chooseObj?.value,
                  label: chooseObj?.label?.replace(/§§/g, ""),
                  checked: true,
                  iconImage: chooseObj?.iconImage,
                },
                "locationIds"
              );
              window.__OmegaEvent(
                "ep_search_advanced_filter_location_searchname_ck",
                "高级搜索-筛选-位置-输入名称",
                {
                  source: chooseObj?.label?.replace(/§§/g, ""),
                }
              );
            }}
          />
        </MultiChoose>
      </div>

      <div
        className={cx("filter-item", {
          hidden: !canFilter("module"),
        })}
      >
        <div className={cx("filter-item-title")}>{intl.t("模块")}</div>
        <MultiChoose
          defaultOptions={displayObj.moduleTypes ?? []}
          changeChooseList={(vArray) => changeChooseList(vArray, "moduleTypes")}
          overlayClassName={"no-input-style"}
        ></MultiChoose>
      </div>

      <div
        className={cx("filter-item", {
          hidden: !canFilter("owner"),
        })}
      >
        <div className={cx("filter-item-title")}>{intl.t("所有者")}</div>
        <MultiChoose
          defaultOptions={displayObj.owners ?? []}
          changeChooseList={(vArray) => changeChooseList(vArray, "owners")}
          removeChooseItem={(value) => removeChooseItem(value, "owners")}
        >
          <SelectAnyOption
            placeholder={intl.t("请输入所有者名称")}
            onChoose={(vObj) => {
              changeDisObj(
                {
                  value: `${vObj?.ldap}@${vObj?.orgId}`,
                  label: vObj?.title?.replace(/§§/g, ""),
                  checked: true,
                  tenantId: vObj?.orgId
                },
                "owners"
              );
              window.__OmegaEvent(
                "ep_search_advanced_filter_owner_searchname_ck",
                "高级搜索-筛选-所有者-输入名称",
                {
                  source: vObj?.title?.replace(/§§/g, ""),
                }
              );
            }}
            showRecent={false}
            OptionRender={({ searchValue, onChooseCallback }) => {
              return (
                <NormalOptions
                  itemRequest={getUserData}
                  itemRender={(v) => <UserItem optionData={v} />}
                  id={"owner-select"}
                  searchValue={searchValue}
                  onChooseCallback={onChooseCallback}
                />
              );
            }}
          ></SelectAnyOption>
        </MultiChoose>
      </div>
      {isDiDiTenant() && (
        <div
          className={cx("filter-item", {
            hidden: !canFilter("tags"),
          })}
        >
          <div className={cx("filter-item-title")}>
            <p className={cx("tag-title-content")}>
              {intl.t("标签")}
              <HelpLink title={intl.t("筛选知识库页面")} />
            </p>
          </div>
          <MultiChoose
            defaultOptions={displayObj.tagIds ?? []}
            changeChooseList={(vArray) => changeChooseList(vArray, "tagIds")}
            removeChooseItem={(value) => removeChooseItem(value, "tagIds")}
          >
            <SelectAnyOption
              placeholder={intl.t("请输入标签")}
              onChoose={(vObj) => {
                changeDisObj(
                  {
                    value: vObj?.id,
                    label: vObj?.name?.replace(/§§/g, ""),
                    checked: true,
                  },
                  "tagIds"
                );
                window.__OmegaEvent(
                  "ep_search_advanced_filter_label_ck",
                  "高级搜索-筛选-标签-输入名称",
                  {
                    source: vObj?.title?.replace(/§§/g, ""),
                  }
                );
              }}
              showRecent={false}
              OptionRender={({ searchValue, onChooseCallback }) => {
                return (
                  <NormalOptions
                    itemRequest={searchTags}
                    itemRender={(v) => <TagItem optionData={v} />}
                    searchValue={searchValue}
                    onChooseCallback={onChooseCallback}
                  />
                );
              }}
            ></SelectAnyOption>
          </MultiChoose>
        </div>
      )}
      <div
        className={cx("filter-item", {
          hidden: !canFilter("times"),
        })}
      >
        <div className={cx("title-has-reset")}>
          <p>{intl.t("最近浏览时间")}</p>
          {displayObj.time !== "" && (
            <div
              className={cx("reset")}
              onClick={() => updateDisplayObj("", "time")}
            >
              {intl.t("重置")}
            </div>
          )}
        </div>
        <Radio.Group
          className={"all-radio-style"}
          onChange={(v) => {
            updateDisplayObj(v.target.value, "time");
            v.target.value &&
              window.__OmegaEvent(
                "ep_dkpc_dk_advancedsearch_chooserecentview_ck",
                "",
                {
                  recentview: configMap()[v.target.value],
                }
              );
            window.__OmegaEvent(
              "ep_search_advanced_filter_opened_ck",
              "高级搜索-筛选-浏览时间",
              {
                source: v.target.value,
              }
            );
          }}
          value={displayObj.time}
        >
          {getSearchConfig().time.map((v, i) => (
            <Radio key={i} value={v.value}>
              {v.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>

      <div
        className={cx("filter-item", {
          hidden: !canFilter("fileType"),
        })}
      >
        <div className={cx("title-has-reset")}>
          <p>{intl.t("类型")}</p>
          {displayObj.fileType !== "0" && (
            <div
              className={cx("reset")}
              onClick={() => updateDisplayObj("0", "fileType")}
            >
              {intl.t("重置")}
            </div>
          )}
        </div>

        <Radio.Group
          className={"all-radio-style"}
          onChange={(v) => {
            updateDisplayObj(v.target.value, "fileType");

            window.__OmegaEvent(
              "ep_search_advanced_filter_type_ck",
              "高级搜索-筛选-类型",
              {
                source: v.target.value,
              }
            );
          }}
          value={displayObj.fileType}
        >
          {getSearchConfig().fileType.map((v, i) => (
            <Radio key={i} value={v.value}>
              {v.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default Filter;
