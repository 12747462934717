import { get } from '@/utils/request';
import axios from 'axios';
import api from '@/utils/request/api/DkApi';

export async function getOfficialAccount(params) {
  try {
    const res = await get(api.GET_OFFICIAL_ACCOUNT, { params });
    return res;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getSpaceList(params) {
  try {
    const res = await get(api.GET_COMMUNITY_SPACE, { params });
    return res;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function saveTalkDraft(params) {
  try {
    const res = await axios.post(api.SAVE_TALK_DRAFT, params);
    return res?.data || res;
  } catch (e) {
    console.log(e);
  }
}

