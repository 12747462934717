import App from '@/cooperApp';
import ReactDOM from 'react-dom';
import React from 'react'

// 需要同步init的逻辑
window.React = React;
window.ReactDOM = ReactDOM;

ReactDOM.render(<App />, document.getElementById('root'), () => {
  let styleTag = document.getElementById('index-skeleton-style');
  let styleIconfontTag = document.getElementById('ndex-skeleton-iconfont-style');
  if (styleTag) {
    styleTag.textContent = '';
    console.log('Skeleton styles in <head> have been deleted successfully.');
  } else {
    console.log('Skeleton styles tag <style> is not found.');
  }
  if (styleIconfontTag) {
    styleIconfontTag.textContent = '';
    console.log('Skeleton iconfont styles in <head> have been deleted successfully.');
  } else {
    console.log('Skeleton iconfont styles tag <style> is not found.');
  }
});
