import React, { useState } from "react";
import { Button, Modal, Tooltip, Upload } from "antd";

import { intl } from "di18n-react";
import styles from "./index.module.less";
import mountAnywhere from "@/utils/mountAnywhere";
import importWiki from "./icon/import_wiki.png";
const { Dragger } = Upload;
const ImportWikiModal = ({ knowledgeId, pageId, addUploadTask, onClose }) => {
  const [fileList, setFileList] = useState([]);
  const handleUpload = () => {
    fileList.forEach((file) => {
      addUploadTask(file, {
        knowledgeId,
        currPageId: 0,
        parentId: pageId,
        type: "confluence",
        modeType: 1, // @linyu
      });
    });
    onClose();
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => [...prevFileList, file]);

      return false;
    },
    fileList,
    multiple: true,
    accept: ".zip",
    itemRender: (_originNode, file, _fileList, actions) => {
      return (
        <div>
          <i className="icon-zip" />
          <div className="single-line">{file.name}</div>

          <Tooltip
            title={intl.t("移除")}
            overlayClassName="dk-ant-tooltip__reset"
          >
            <div
              className="close-icon"
              onClick={() => {
                console.log("close");
                actions?.remove(file);
              }}
            >
              <i className="dk-iconfont dk-icon-guanbi1" />
            </div>
          </Tooltip>
        </div>
      );
    },
  };

  const jumpToHelp = () => {
    window.open(
      "https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199305367467",
      "_black"
    );
  };

  const contentText = (
    <div>
      <p className={styles["fond-bond"]}>
        {intl.t("第一步：将wiki空间以html格式导出")}
      </p>
      <div className={styles["wiki-help"]}>
        <img src={importWiki} className={styles["wiki-help-image"]} />
        <Button className={styles["wiki-help-button"]} onClick={jumpToHelp}>
          {intl.t("查看帮助文档")}
        </Button>
      </div>
      <p className={styles["fond-bond"]}>
        {intl.t("第二步：在此上传wiki导出的html压缩包（.zip格式）")}
      </p>
      <Dragger {...props}>
        <i className="dk-iconfont dk-icon-yunshangchuan1" />{" "}
        {intl.t("上传压缩包")}
      </Dragger>
    </div>
  );

  const footerButtons = (
    <>
      <Button className={styles["import-wiki-btn-gray"]} onClick={onClose}>
        {intl.t("取消") + " "} 
      </Button>
      <Button
        className={styles["import-wiki-btn"]}
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
      >
        {intl.t("导入") + " "}
      </Button>
    </>
  );

  return (
    <Modal
      centered
      width={460}
      className={styles["import-wiki-modal"]}
      visible={true}
      maskClosable={false}
      footer={null}
      closable={true}
      onCancel={() => onClose()}
      zIndex={999}
      transitionName=""
      maskTransitionName=""
    >
      <div className={styles["import-wiki-title"]}>
        {intl.t("导入Wiki页面")}
      </div>
      <div className={styles["import-wiki-title-content-text"]}>
        {contentText}
      </div>
      <div className={styles["import-wiki-footer"]}>{footerButtons}</div>
    </Modal>
  );
};
let previousNotice = null;

const closePreviousNotice = () => {
  if (previousNotice) {
    previousNotice();
    previousNotice = null;
  }
};

const importWikiModal = (props) => {
  closePreviousNotice();

  const handleClose = () => {
    closePreviousNotice();
  };

  const Comp = <ImportWikiModal {...props} onClose={handleClose} />;

  const close = mountAnywhere(Comp);
  previousNotice = close;
};

export default importWikiModal;
