const commonCss = require('./commonCss');

module.exports = `
<style>
  ${commonCss}
  #common-team-skeleton .cooper-notify-count  {
    position        : absolute;
    top             : -6px;
    left            : 10px;
    background-color: #ff563a;
    border          : 1px solid #fff;
    color           : #fff;
    display         : inline-block;
    min-width       : 16px;
    height          : 16px;
    font-size       : 12px;
    line-height     : 14px;
    border-radius   : 8px;
    text-align      : center;
    cursor          : pointer;
    padding         : 0 4px;
    z-index         : 100
  }
  #common-team-skeleton .notify-list-small>span  {
    left: 18px;
    top : 2px
  }
  #common-team-skeleton .global-search-wrap .search-tag  {
    margin-left  : 8px;
    padding      : 0 4px 0 8px;
    border-radius: 3px;
    background   : #ebeef1;
    display      : flex;
    align-items  : center;
    color        : rgba(0, 0, 0, .7)
  }
  #common-team-skeleton .header-warp .header-top .title-opt>button{
    width: unset !important; /* NOTE 重新修订，因为home的这个width强制设置84是为了修复其他样式bug，这里再改回unset */
  }

  #common-team-skeleton .global-search-wrap .search-tag>span>i  {
    color    : #333;
    font-size: 14px;
    cursor   : pointer
  }
  #common-team-skeleton .team-skeleton-box  {
    width  : 100%;
    padding: 0 32px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-button *  {
    min-width: auto !important;
    width    : 100% !important;
    height   : 100% !important
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-title  {
    z-index      : 1;
    width        : 64px;
    height       : 16px;
    margin-top   : 4px;
    margin-bottom: 20px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-list  {
    width                : 100%;
    display              : grid;
    gap                  : 16px;
    grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
    padding-bottom       : 32px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-list .team-skeleton-box-item  {
    background-color: #f6f7f7;
    position        : relative;
    border-radius   : 8px;
    padding         : 0;
    height          : 92px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-list .team-skeleton-box-item .team-skeleton-bi-logo  {
    display : block;
    position: absolute;
    top     : 18.4px;
    left    : 18.4px;
    width   : 19.2px;
    height  : 19.2px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-list .team-skeleton-box-item .team-skeleton-bi-name  {
    display : block;
    position: absolute;
    top     : 21px;
    left    : 48px;
    width   : 120px;
    height  : 14px
  }

  #common-team-skeleton .team-skeleton-box .team-skeleton-box-list .team-skeleton-box-item .team-skeleton-bi-info  {
    display : block;
    position: absolute;
    top     : 60px;
    left    : 18px;
    height  : 12px;
    width   : 220px
  }

  #common-team-skeleton .team-skeleton-wrap  {
    width     : 100%;
    height    : 100%;
    position  : relative;
    overflow-y: auto
  }
  #common-team-skeleton .dk-icon-tuanduikongjian:before  {
    content: "\e751"
  }
  #common-team-skeleton .dk-icon-tuanduikongjian:before  {
    content: "\e751"
  }
</style>
<div id='common-team-skeleton'>
  <div class="team-skeleton-wrap">
    <div class="team-skeleton-box">
      <div class="team-skeleton-box-title team-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
      <div class="team-skeleton-box-list">
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-skeleton-box">
      <div class="team-skeleton-box-title team-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
      <div class="team-skeleton-box-list">
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-skeleton-box">
      <div class="team-skeleton-box-title team-skeleton-button">
        <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
          <span class="ant-skeleton-button ant-skeleton-button-round"></span>
        </div>
      </div>
      <div class="team-skeleton-box-list">
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
        <div class="team-skeleton-box-item">
          <div class="team-skeleton-bi-logo team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element">
              <span class="ant-skeleton-button ant-skeleton-button-square"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-name team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
          <div class="team-skeleton-bi-info team-skeleton-button">
            <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
              <span class="ant-skeleton-button ant-skeleton-button-round"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`
