import { COOPER_ASIDE_TYPE } from '@/constants';
import { getUserView } from '@/service/cooper/userView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// 处理LayoutCooper LayoutDk中的公共逻辑
export default () => {
  const dispatch = useDispatch();
  const { getGlobalOutsideChain } = dispatch.CooperIndex;
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);
  const { userViewData } = useSelector((state) => state.GlobalData);
  const { setUserViewData, setNeedAsideSkeleton } = dispatch.GlobalData;
  const initUserView = async () => {
    const obj = await getUserView();
    if (obj?.Aside_Type_Cooper === COOPER_ASIDE_TYPE.SMALL) {
      setNeedAsideSkeleton(false)
    }
    setUserViewData(obj);
  }

  useEffect(() => {
    if (JSON.stringify(userViewData) === '{}') {
      initUserView();
    }
  }, [userViewData])

  // useEffect(() => {
  //   if (JSON.stringify(profile) === '{}') {
  //     reqProfile();
  //   }
  // }, [profile])

  useEffect(() => {
    if (JSON.stringify(globalOutsideChain) === '{}') {
      setTimeout(() => {
        getGlobalOutsideChain();
      }, 2000);
    }
  }, [globalOutsideChain]);
};
