import SkeletonPage from '@/components/SkeletonPage';
import store from '@/model';
import React, { Suspense } from 'react';

const Home = React.lazy(() => import(/* webpackChunkName: "homK" */ '@/pages/knowledge/Home'));
const SetUp = React.lazy(() => import(/* webpackChunkName: "setUK" */ '@/pages/knowledge/SetUp'))
const RecycleBin = React.lazy(() => import(/* webpackChunkName: "recyBK" */ '@/pages/knowledge/RecycleBin'))
const DataBoard = React.lazy(() => import(/* webpackChunkName: "daBoaK" */ '@/pages/knowledge/DataBoard'))
const ErrorPage = React.lazy(() => import(/* webpackChunkName: "errPK" */ '@/pages/knowledge/ErrorPage'))
const PageDetail = React.lazy(() => import(/* webpackChunkName: "pagDK" */ '@/pages/knowledge/PageDetail'))
const EditPage = React.lazy(() => import(/* webpackChunkName: "editPK" */ '@/pages/knowledge/EditPage'))
const Recent = React.lazy(() => import(/* webpackChunkName: "ercKK" */ '@/pages/knowledge/MenHu/RecentKnowledge'))
const ShareBook = React.lazy(() => import(/* webpackChunkName: "shaBoK" */ '@/pages/knowledge/ShareBook'))
const SharePageDetail = React.lazy(() => import(/* webpackChunkName: "shaPaDeK" */ '@/pages/knowledge/ShareBook/SharePageDetail'))
const EditTemplate = React.lazy(() => import(/* webpackChunkName: "edTemK" */ '@/pages/knowledge/EditTemplate'))
const Snapshoot = React.lazy(() => import(/* webpackChunkName: "snapK" */ '@/pages/knowledge/ShareBook/SharePageDetail/snapshoot'))
const ExpireLink = React.lazy(() => import(/* webpackChunkName: "expLK" */ '@/pages/knowledge/ExpireLink'));
const MyKnowledge = React.lazy(() => import(/* webpackChunkName: "myKK" */ '@/pages/knowledge/MenHu/MyKnowledge'))
const Create = React.lazy(() => import(/* webpackChunkName: "creK" */ '@/pages/knowledge/Create'))
const StarKonwledge = React.lazy(() => import(/* webpackChunkName: "starKK" */ '@/pages/knowledge/MenHu/StarKonwledge/index'))
const MenhuList = React.lazy(() => import(/* webpackChunkName: "mhLisK" */ '@/pages/knowledge/MenHu/MenhuList'))
const ViewDocument = React.lazy(() => import(/* webpackChunkName: "vDocK" */ '@/pages/knowledge/ViewDocument'))
const ViewShareDocument = React.lazy(() => import(/* webpackChunkName: "vShaDocK" */ '@/pages/knowledge/ViewShareDocument'))
const SpaceDetail = React.lazy(() => import(/* webpackChunkName: "spaDK" */ '@/pages/cooper/SpaceDetail'));

let RouteInTeam = [
  { // 注意：父路由没有被注册，需要加一个根路径
    path: '/',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  },
  {
    path: '/knowledge/:knowledgeId/home',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><Home /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/setUp',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><SetUp /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/recycleBin',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><RecycleBin /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/dataBoard',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><DataBoard /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/:pageId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><PageDetail /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/:pageId/edit',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><EditPage /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'Normal',
    },
  },
  {
    path: '/knowledge/:knowledgeId/template/:templateId/edit',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><EditTemplate /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
]

let routesDKglobal = [
  {
    path: '/knowledge',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><MyKnowledge /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><MyKnowledge /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/snapshoot/:snapshootId/:version',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><Snapshoot /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
  {
    path: '/knowledge/errorPage',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><ErrorPage /></Suspense>,
    meta: {
      headerType: 'Normal',
      asideType: 'None',
    },
  },
  {
    path: '/knowledge/expire',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><ExpireLink /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
  {
    path: '/knowledge/create',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><Create /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/recent',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><Recent /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/portalList',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><MenhuList /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/star',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><StarKonwledge /></Suspense>,
    meta: {
      headerType: 'PersonalMenHu',
      asideType: 'PersonalMenHu',
    },
  },
  {
    path: '/knowledge/share/book/:shareId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><ShareBook /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'Share',
    },
  },
  {
    path: '/knowledge/share/book/:shareId/:pageId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><SharePageDetail /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'Share',
    },
  },
  {
    path: '/knowledge/share/page/:shareId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><SharePageDetail /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
  {
    path: '/knowledge/view/page/:shareId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><ViewShareDocument /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
  {
    path: '/knowledge/view/:knowledgeId/:pageId',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><ViewDocument /></Suspense>,
    meta: {
      headerType: 'None',
      asideType: 'None',
    },
  },
];


let dkRouteInTeam = []
let routesDK = []
// eslint-disable-next-line import/prefer-default-export
export const getDkRoute = () => {
  const { IsExternalTenant, tenantConfig } = store.getState().GlobalData
  if (IsExternalTenant && tenantConfig.length !== 0) {
    // 这里为了配合首页的非法路由的判断做了一个兼容
    dkRouteInTeam = [{}]
    routesDK = [{}]
  } else {
    dkRouteInTeam = RouteInTeam
    routesDK = routesDKglobal.concat(RouteInTeam)
  }
  return { dkRouteInTeam, routesDK };
}


