export const OmegaEventId = {
  personal: 'ep_personal_feedbackai_ck', // 点击Cooper个人空间右上角智能客服
  team: 'ep_team_feedbackai_ck', // 点击Cooper团队空间右上角智能客服
  document: 'ep_document_feedbackai_ck', // 在Cooper文件内点击右上角智能客服
  prompt: 'ep_feedbackai_prompt_ck', // 点击提示语
  stop: 'ep_feedbackai_stopgenerating_ck', // 生成回答的时候点击停止生成
  input: 'ep_feedbackai_input_typ', // 输入框输入问题
  send: 'ep_feedbackai_send_ck', // 输入问题并发送
  likeSummary: 'ep_feedbackai_helpful_ck', // 回答后点击 有帮助
  cancelLike: 'ep_feedbackai_helpful_cancel_ck', // 回答后点击取消“有帮助”
  dislikeSummary: 'ep_feedbackai_nohelp_ck', // 回答后点击 无帮助
  cancelDislike: 'ep_feedbackai_nohelp_cancel_ck', // 回答后点击取消“无帮助”
  regenerate: 'ep_feedbackai_regenerate_ck', // 回答后点击 重新生成
  referenceSource: 'ep_feedbackai_reference_ck', // 推荐参考来源后点击参考链接
  dislikeResons: 'ep_feedbackai_reference_ck', // 在智能客服页面回答不满意答案的原因
  feedbackaiDc: 'ep_feedbackai_dc_ck', // 在智能客服页面点击DC客服入口，跳转到DC的Cooper反馈群
}
