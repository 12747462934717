/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-17 11:57:42
 * @LastEditTime: 2023-07-25 16:12:58
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/service/cooper/personSpace.js
 *
 */
import { intl, getLocale } from 'di18n-react';
import { get } from '@/utils/request/cooper';
import { formatRecentTime, getUserUidFromCookie } from '@/utils/index';
import CooperApi from '@/utils/request/api/CooperApi';

function formatMeta(item, teamId) {
  let owner = '';
  if (getUserUidFromCookie() == item.create_by_member_id) {
    owner = intl.t('我');
  } else {
    owner = getLocale() === 'en-US' ? item.create_by : item.create_by_cn;
  }

  return ({
    teamId,
    id: item.id,
    name: item.display_name,
    image: item.tiny_image,
    owner,
    updateTime: formatRecentTime(item.modify_on),
    objectType: item.space_resource_type,

    type: item.type,
    mimeType: item.mime_type,
    markedStar: item.marked_star,

    // 默认文件夹有子节点
    hasChild: item.type === 1,

    // 勾选状态，0-未勾选，1-已勾选，2-半勾选
    checkStatus: 0,

    // XXX: 兼容老的操作
    ...item,
  });
}
export async function getResourceContact(resourceId) {
  const url = CooperApi.RESOURCE_CONTACT.replace(':resourceId', resourceId);
  const res = await get(url);
  return res;
}

export function createGetFiles(id, teamId, pageSize, sortBy, orderAsc) {
  let pageNum = 0; // 这里的值形成闭包，一直存在
  let isEnd = false;
  const result = [];
  // let isLoading = false; // 新增的标志变量

  const url = (
    teamId
      ? CooperApi.TEAM_SPACE_FILES.replace(':teamId', teamId)
      : CooperApi.PERSONAL_SPACE_FILES
  );

  return async function () {
    // if (isLoading) return;
    if (isEnd) return { result, isEnd };

    // isLoading = true;
    try {
      const res = await get(url, {
        params: {
          id,
          pageNum: pageNum++, // 在这里获取到闭包中的值（累加过的）
          // pageNum,
          pageSize,
          sortBy,
          orderAsc,
        },
        keepSilent: true,
        needDD05: true,
      });

      isEnd = res.items.length === 0;
      result.push(...res.items.map((item) => formatMeta(item, teamId)));
      // pageNum = res.currentPage + 1; // 当第一页存在文件没有权限的时候，当页数据个数<pageSize
      // 这种方案会导致同一个pageNum多次加载
    } catch (e) {
      return { result: e };
    }
    // finally {
    //   isLoading = false;
    // }


    return { result, isEnd };
  };
}

export async function getChildFiles(id, teamId, pageOption = {}) {
  // 子文件夹不支持动态加载，使用如下固定的参数
  const pageNum = 0;
  const pageSize = 1000;
  const { sortBy = 'modify_time', orderAsc = 0 } = pageOption;
  const url = (
    teamId
      ? CooperApi.TEAM_SPACE_FILES.replace(':teamId', teamId)
      : CooperApi.PERSONAL_SPACE_FILES
  );

  const res = await get(url, {
    params: {
      id,
      pageNum,
      pageSize,
      sortBy,
      orderAsc,
    },
    needDD05: true,
  });

  return res.items.map((item) => formatMeta(item, teamId));
}

export async function getMyQuota() {
  const res = await get(CooperApi.PERSONAL_SPACE_QUOTA_DETAIL);

  const {
    proportion,
    quota_bpm_url: quotaBpmUrl,
    used_quota: usedQuota,
    space_quota: spaceQuota,
    space_id: personalSpaceId,
    space_name: personalSpaceName,
    space_name_en: personalSpaceNameEn,
  } = res;

  return { proportion, quotaBpmUrl, usedQuota, spaceQuota, personalSpaceId, personalSpaceName, personalSpaceNameEn };
}
