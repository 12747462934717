import { NotificationWarnIcon, ModalCloseIcon } from '@/assets/icon';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './style.module.less';
import classnames from 'classnames/bind';
import { useState } from 'react';

const cls = classnames.bind(styles);


const HintHeader = ({ title = '', canClose = true }) => {
  const [showHint, setShowHint] = useState(true);

  return (
    <div
      className={cls('hint-header', {
        'hidden-hint': !showHint,
      })}
      id="hint-header"
    >
      {/* {<img
        className={cls('hint-icon')}
        src={NotificationWarnIcon} />} */}
      <InfoCircleOutlined className={cls('hint-icon')} />
      <div className={cls('hint-body')}>
        <div className={cls('hint-title')}>{title}</div>
        {canClose && <div className={cls('hint-close-icon-wrap')}>
          <img
            className={cls('hint-close-icon')}
            onClick={() => {
              setShowHint(false);
            }}
            src={ModalCloseIcon} />
        </div>}
      </div>
    </div >
  );
};

export default HintHeader;
