import React, { useEffect, useState } from 'react'
import BackTop from './BackTop';
import FullTextComment from './FullTextComment';
import './index.less';
import _ from 'lodash-es';
import HelpGuide from './HelpGuide';
import BackLastPos from './BackLastPos';

let pageId;
export default (props) => {
  const { sourceType, editable, commentConfig, offline, backTopVisible } = props
  const [showBack, setShowBack] = useState(false)
  const [showBackLastPos, setShowBackLastPos] = useState(false)

  const isShowBackTop = _.debounce((event) => {
    setShowBackLastPos(false);
    const distance = event.target.scrollTop;
    let screenHeight = window.screen.height
    if (distance > screenHeight) {
      setShowBack(true)
    } else {
      setShowBack(false)
    }
    // 存位置
    const clientHeight = event.target.clientHeight;
    // const lastPos = distance >= clientHeight * 2 ? distance.toString() : ''; // 两页内滚动，不记
    localStorage.setItem("lastPos_" + pageId, distance ? distance.toString() : '');
  }, 30, {
    leading: false,
    trailing: true,
  })
  useEffect(() => {
    // 页面 ID
    pageId = location.pathname.split('/').pop()
    setShowBackLastPos(!!localStorage.getItem("lastPos_" + pageId))
    let scrollDoc = document.querySelector('#knowledge_editor_box');
    scrollDoc?.addEventListener('scroll', isShowBackTop, false);
    return () => {
      scrollDoc?.removeEventListener('scroll', isShowBackTop, false);
    };
  }, []);

  const container = (
    <div className='utils-drawer'>
      {backTopVisible && showBack && <BackTop sourceType={sourceType} />}
      {showBackLastPos && <BackLastPos sourceType={sourceType}></BackLastPos>}
      {commentConfig?.show && !editable && <FullTextComment />}
      {!offline && <HelpGuide editable={editable}/>}
    </div>
  )

  return container;
}
