/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-05-19 11:19:55
 * @LastEditTime: 2023-09-13 17:02:54
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/utils/path.js
 *
 */
import { get } from '@/utils/request/cooper';
import api from './request/api/CooperApi';
import { FileType } from '@/constants/cooper';

const docPath = '/docs/document';
const docPath2 = '/docs2/document'
const sheetPath2 = '/docs2/sheet'
const sheetPath = '/docs/sheet';
const slidePath = '/docs/slide';
const ddocPath = '/didoc/file';
const flowPath = '/docs/flow/draw';
const diagramingPath = '/docs/diagraming';
const mindmapPath = '/docs/mindmap';

const utils = {
  // 6:表格  7:文档  8:PPT 10:PO流程图  11: PO思维导图
  getCoopPath(id, mimeType, type = 2) {
    switch (Number(mimeType)) {
      case 6:
        if (type === FileType.SHIMO2_EXCEL) {
          return `${sheetPath2}/${id}`;
        }
        if (type === FileType.COOPER_SHEET) {
          return `${sheetPath}/${id}`;
        }
        return `${sheetPath}/${id}`;
      case 7:
        if (type === FileType.SHIMO2_WORD) {
          return `${docPath2}/${id}`
        }
        if (type === FileType.COOPER_DOC || type === FileType.COOPER_ANONYMOUS_DOC || type === 2) {
          return `${docPath}/${id}`
        }
        return `${ddocPath}/${id}`
      case 8:
        return `${slidePath}/${id}`;
      case 10:
        return `${diagramingPath}/${id}`;
      case 11:
        return `${mindmapPath}/${id}`;

      default:
        throw new Error('unknown file type');
    }
  },
  getCoopSharePath(code) {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/docs/sharing/${code}`;
  },
  // EXCEL
  getSheetPath(id) {
    return `${sheetPath}/${id}`;
  },
  // PPT
  getSlidePath(id) {
    return `${slidePath}/${id}`;
  },
  // 石墨文档
  getDocPath(id) {
    return `${docPath}/${id}`;
  },
  // 氢文档
  getDiDocPath(id) {
    return `${window.location.origin}${ddocPath}/${id}`;
  },

  getDkPageUrl(knowledgeId, pageId) {
    return `${window.location.origin}/knowledge/${knowledgeId}/${pageId}`;
  },
  async getFlowChart(id) {
    const res = await get(api.RESOURCE_DOC.replace(':resourceId', id), {
      headers: {
        'X-App-Id': 2,
      },
    });
    let docId = res.guid;
    const { fileName } = res;
    return `${flowPath}?docId=${docId}&resourceId=${id}#L${fileName}`;
  },

  resolve(...args) {
    let str = `/${args.join('/')}`;
    return str.replace(/\/+/g, '/');
  },
};

export default utils;
