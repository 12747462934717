import { getWithSuccess } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export async function network() {
  try {
    const res = await getWithSuccess(api.HELP_NETWORK);

    if (!res.success) return 'dataError';
    return {
      resultType: res.useDiDiVPN ? 'useVpn' : 'normal',
      clientIp: res.clientIp,
    }
  } catch (err) {
    return {
      resultType: 'dataError',
      clientIp: 'error',
    }
  }
}

