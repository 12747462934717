/**
 *框架样式，处理dc端
 */
import { useEffect, useState } from 'react';
import { intl } from 'di18n-react';
import { inPhone, isDesktopDC } from '@/utils';
import classBind from 'classnames/bind';
import HintHeader from '@/components/HintHeader';
import HeaderWrap from '../HeaderWrap';
import AsideWrap from '../AsideWrap';
import { reportMeasure } from '@/utils/performance';
import { useLocation } from 'react-router-dom';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const isInPhone = inPhone();

const Frame = ({ children, warningTip }) => {
  const [headerType, setHeaderType] = useState();
  const [showH5Hint, setShowH5Hint] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    reportMeasure({
      startName: 'page-start',
      endName: `layout-end-${window.location.pathname}`,
      measureName: 'layout-time',
    });

    if (isDesktopDC) {
      document.body.style.minWidth = '580px';
    }
    if (warningTip?.isShow) {
      // 变更layout高度，防止滚定
      document.getElementById('main-layout-dk')?.classList.add(cx('main-height-in-tips'));
      // 隐藏原有的知识库移动端提示
      setShowH5Hint(false)
    } else {
      document.getElementById('main-layout-dk')?.classList.remove(cx('main-height-in-tips'));
      setShowH5Hint(true)
    }

  }, []);

  useEffect(() => {
    if (warningTip?.isShow) {
      // 变更layout高度，防止滚定
      document.getElementById('main-layout-dk')?.classList.add(cx('main-height-in-tips'));
      // 隐藏原有的知识库移动端提示
      setShowH5Hint(false)
    } else {
      document.getElementById('main-layout-dk')?.classList.remove(cx('main-height-in-tips'));
      setShowH5Hint(true)
    }
  }, [warningTip])

  const forHeaderType = (type) => {
    setHeaderType(type);
  };

  return (
    <div
      className={cx('main-layout-dk', {
        'main-layout-dk-dc-menhu': isDesktopDC,
        'main-width-in-phone': isInPhone,
      })}
      id='main-layout-dk'
    >
      <HeaderWrap forHeaderType={forHeaderType} />
      <div
        className={cx('main-dk', {
          'main-height-has-header':
            headerType !== 'None' && !pathname.includes('/team-file'),
          'main-width-in-phone': isInPhone,
          'main-dk-dc': isDesktopDC,
        })}
      >
        <AsideWrap />

        <div
          className={cx('body-content-wrap', {
            'body-content-wrap-dc': isDesktopDC && !pathname.includes('/team-file') && !pathname.includes('/template'),
          })}
        >
          {isInPhone && showH5Hint &&  (
            <HintHeader
              title={intl.t('如需查看更多知识库内容，请前往PC端')}
              showOperation
            />
          )}

          <div
            id="knowedge-body-content"
            className={cx('body-content', {
              'body-content-inphone': isInPhone
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frame;
