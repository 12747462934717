import React, { useEffect, useState } from 'react';
import closeIcon from './icon/close.png';
import warnIcon from './icon/warn.png';
import styles from './index.module.less';
import classBind from 'classnames/bind';
// import DOMPurify from 'dompurify';
import { getLocale } from 'di18n-react';
import GetHtml from '@/utils/DOMPurify';
import { isDC, inPhone } from '@/utils/index';

const cx = classBind.bind(styles);

const CooperAnnounceHalo = ({ tips }) => {
  const [ announce, setAnnounce ] = useState('');

  useEffect(() => {
    const text = getLocale() === 'en-US' ? tips?.contentEN : tips?.contentCN;
    setAnnounce(text);
  }, [tips]);



// parco 
  return (
    tips?.isShow ? (
      <div className={cx('cooper-announce', {
        'cooper-announce-dc': isDC() || inPhone()
      })}>
        <img className={cx('warnIcon')} src={warnIcon} />
        <span dangerouslySetInnerHTML={{ __html: GetHtml(announce)}}></span>
        {/* <button
          className={cx('btn-cc')}
          onClick={() => {
            setAnnounce('');
          }}
        >
          <img className={cx('closeIcon')} src={closeIcon} />
        </button> */}
      </div>
    ) : null
  )
}

export default CooperAnnounceHalo;

