import { intl } from 'di18n-react';
import { useRef, useContext, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import classBind from 'classnames/bind';
import { CATAlLOG, BOTTOM_MENU } from '@/constants/catalog';
import { TREE_VIEW_TYPE } from '@/constants/index';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import ViewPage from './ViewPage';
import ViewTag from './ViewTag';
import stylesCommon from '../catalogCommon.module.less';
import styles from './style.module.less';
import stylesAside from '@/components/serviceComponents/AsideDashboard';
import { apolloSwitch } from '@/utils/ab';
import { isDesktopDC } from '@/utils';

const cx = classBind.bind(styles);
const cm = classBind.bind(stylesCommon);
const cs = classBind.bind(stylesAside);

export default function AsideContent(props) {
  const { isResizing, asideType } = props;
  const pageViewRef = useRef(null);
  const { knowledgeId } = useContext(LayoutContext);
  const { treeViewType, knowledgeDetail } = useSelector(
    (state) => state.KnowledgeData,
  );
  const [showDataBoard, setShowDataBoard] = useState(null);
  const { teamId } = useParams();

  const onClickSubMenu = (menuFlag) => {
    switch (menuFlag) {
      case 'home':
        window.__OmegaEvent('ep_dkpc_pagehome_ck');
        break;
      case 'setUp':
        window.__OmegaEvent('ep_dkpc_pagesetting_ck');
        break;
      case 'recycleBin':
        window.__OmegaEvent('ep_dkpc_pagetrash_ck');
        break;
      case 'page':
        window.__OmegaEvent('ep_dkpc_pagetree_xialaicon_ck');
        break;
      case 'dataBoard':
        window.__OmegaEvent('ep_dkpc_databoard_ck', '知识库-数据看板');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initGrey();
  }, []);

  const initGrey = async () => {
    const data = await apolloSwitch('data_board_entry');
    setShowDataBoard(data);
  };

  // const BOTTOM_MENU_MAP = useMemo(() => {
  //   if (showDataBoard) return BOTTOM_MENU;
  //   return [
  //     {
  //       key: 1,
  //       title: '设置',
  //       iconName: 'dk-icon-shezhi',
  //       link: '/setUp',
  //       menuFlag: 'setUp',
  //       isFixed: true,
  //     },
  //     {
  //       key: 0,
  //       title: '回收站',
  //       iconName: 'dk-icon-huishouzhan',
  //       link: '/recycleBin',
  //       menuFlag: 'recycleBin',
  //       isFixed: true,
  //     },
  //   ]
  // }, [showDataBoard]);
  return (
    <ul
      className={`${cx('aside-container')} ${cm('aside-container-common')}`}
      // style={{ paddingTop: `${teamId && '0'}` }}
    >
      {teamId && (
        <div className={cx('aside-title')}>{knowledgeDetail.spaceName}</div>
      )}
      {CATAlLOG.map((item) => {
        return (
          <React.Fragment key={item.key}>
            {item.key !== 1 && item.key !== 2 && (
              <li
                className={`${cx({
                  'aside-menu-li': true,
                  'aside-li-fixed': item.isFixed,
                })} ${cm('aside-menu-li-common')}`}
                style={{
                  bottom: item.isFixed ? 40 * Math.abs(item.key - 3) : null,
                }}
              >
                <NavLink
                  onClick={() => onClickSubMenu(item.menuFlag)}
                  to={
                    teamId
                      ? `/team-file/${teamId}/knowledge/${knowledgeId}${item.link}`
                      : `/knowledge/${knowledgeId}${item.link}`
                  }
                  className={({ isActive }) => {
                    return `
                      ${cm('aside-li-item', {
                      'aside-li-item-active': isActive,
                    })} 
                      ${cs({ 'aside-li-item-dc': isDesktopDC })}
                      `;
                  }}
                >
                  <i
                    className={cm({
                      [item.iconName]: true,
                      'menu-root-icon': true,
                      'dk-iconfont': true,
                    })}
                  />

                  <div className={cm('aside-li-text')}>{typeof item.title === 'function' ? item.title() : item.title}</div>
                </NavLink>
              </li>
            )}

            {item.key === 1 && (
              <div
                className={cx('view-wrap')}
                ref={pageViewRef}
                onClick={() => onClickSubMenu(item.menuFlag)}
                style={{ paddingBottom: teamId ? '38px' : '0' }}
              >
                {treeViewType === TREE_VIEW_TYPE.PAGE && <ViewPage isResizing={isResizing} asideType={ asideType} />}
                {treeViewType === TREE_VIEW_TYPE.TAG && <ViewTag />}
              </div>
            )}

            {item.key === 2 && (
              <div
                className={cx('data-board', {
                  'aside-li-fixed': true,
                })}
                style={{
                  bottom: item.isFixed ? 40 * Math.abs(item.key - 3) : null,
                }}
              >
                {BOTTOM_MENU.map((value, key) => {
                  return (
                    <div
                      key={key}
                      className={cx({
                        'data-board__btn': true,
                        'data-board__btn-hidden': value.key === 2,
                      })}>
                      <li
                        className={`${cx({
                          'aside-menu-li': true,
                          'aside-menu-li-common-reset': true,
                        })} ${cm('aside-menu-li-common')}`}
                      >
                        <NavLink
                          onClick={() => onClickSubMenu(value.menuFlag)}
                          to={
                            teamId
                              ? `/team-file/${teamId}/knowledge/${knowledgeId}${value.link}`
                              : `/knowledge/${knowledgeId}${value.link}`
                          }
                          className={({ isActive }) => {
                            return `
                                ${cm('aside-li-item', {
                              'aside-li-item-active': isActive,
                            })} 
                                ${cx('aside-li-item-reset')}
                                ${cs({ 'aside-li-item-dc': isDesktopDC })}
                                `;
                          }}
                        >
                          <i
                            className={`${cx({
                              'menu-root-icon-reset': true,
                            })} ${cm({
                              [value.iconName]: true,
                              'menu-root-icon': true,
                              'dk-iconfont': true,
                            })}`}
                          />

                          <div
                            className={`${cx({
                              'aside-li-text-reset': true,
                            })} ${cm('aside-li-text')}`}
                          >
                            {typeof value.title === 'function' ? value.title() : value.title}
                          </div>
                        </NavLink>
                      </li>
                    </div>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
}
