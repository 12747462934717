import React from 'react';
import NsModal from './index';
import mountAnywhere from '@/utils/mountAnywhere';

let showing = false;

export default function nsWarn() {
  if (!showing) {
    showing = true;
    mountAnywhere(<NsModal onClose={() => { showing = false; }}/>);
  }
}
