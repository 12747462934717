import { get, post, del, put } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';

export function openVpnPage() {
  window.open('https://itsm.didichuxing.com/admin/index.do?portal=true#downloadSoftware_portalSoftwareDetail_d0f19abef5b063d3ba6219e8e2f7c205');
}

export async function isNonSensitive() {
  const res = await get(CooperApi.APP_VERSION);
  return res.restricted;
}
