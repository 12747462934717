import React, {
  useState, useEffect, useMemo,
} from 'react';
import classNames from 'classnames/bind';
import { Modal, Button, Input, message } from 'antd';
import { intl } from 'di18n-react';
import { getApolloConfig } from '@/utils/ab';
import { applyHigherPermFn, applyOwnerPermCheck, applyOwnerPermFn } from '../service';
import { DkOptionGlobal } from '@/baseComponents/ChooseMemberPerm/constant';
import cooperConfirm from '@/components/common/CooperConfirm';
import ChooseMemberPerm from '@/baseComponents/ChooseMemberPerm';
import { openNewWindow } from '@/utils';

import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';

import styles from './style.module.less';


const { TextArea } = Input;

const cx = classNames.bind(styles);

const OWNER_PERM_DK = 'PAGE_OWNER';
const ADMIN_PERM_DK = 'PAGE_ADMIN';
const OWNER_PERM = 'owner';
const ADMIN_PERM = 'admin';


const PERM_FORMATE = {
  1: 'read',
  2: 'edit',
  32: 'download',
};

const roleExitMap = () => {
  return {
    0: intl.t('查看'),
    1: intl.t('编辑'),
    2: intl.t('下载'),
    3: intl.t('成员'),
    4: intl.t('管理员'),
    5: intl.t('所有者'),
  }
}

const getExitRole = (permCurr) => {
  if (Number.isNaN(Number(permCurr))) {
    const newPerm = `0,${permCurr}`
    const formateArr = newPerm.split(',').map((item) => roleExitMap()[Number(item)]);
    return formateArr.join('/');
  }
  return roleExitMap()[permCurr];
}


const ApplyPermModal = ({
  onClose,
  roleKey,
  isDk,
  permType,
  resourceId,
}) => {
  const [choosedData, setChoosedData] = useState({});
  const [reason, setReason] = useState();
  const [globalOutsideChain, setGlobalOutsideChain] = useState({});

  const notification = useNotification();


  useEffect(() => {
    getApolloConfig('chain_of_outside').then((res) => {
      setGlobalOutsideChain(res);
    });
  }, []);


  const checkOwner = () => {
    return applyOwnerPermCheck({ resourceId }).then((res) => {
      const { code, approver = {}, applicant = {}, bpmUrl } = res;
      const { ldap, cnName } = approver;
      if (code === 10002) { // 别人提交了所有权
        cooperConfirm({
          title: intl.t('无法提交申请'),
          cancelText: intl.t('关闭'),
          okText: intl.t('联系申请者'),
          onOk: () => {
            openNewWindow(`dchat://im/start_conversation?name=${applicant.ldap}`)
          },
          content: <span>
            <a
              style={{ color: '#127FF0' }}
              href={`dchat://im/start_conversation?name=${applicant.ldap}`}
          >
              {applicant.cnName}
            </a> {intl.t('已提交所有权申请，你暂时无法提交申请')}
          </span>,
        })
        onClose();
      } else if (code === 10001) { // 自己申请重复
        cooperConfirm({
          title: intl.t('无法重复提交申请'),
          cancelText: intl.t('知道了'),
          okText: intl.t('查看详情'),
          onOk: () => {
            openNewWindow(bpmUrl)
          },
          content: <span>
            {intl.t('你已提交过所有权申请，由{slot0}进行审批。请等待审批结果', {
              slot0: <a
                style={{ color: '#127FF0' }}
                href={`dchat://im/start_conversation?name=${ldap}`}
            >
                {cnName}
              </a>,
            })}
          </span>,
        });
        onClose();
      } else if (code === 201025) { // 暂无审批人
        message.error(<span className={cx('success-tip')}>
          {intl.t('暂无审批人，{slot0}', {
            slot0: <a
              style={{ color: '#127FF0' }}
              href={globalOutsideChain.no_approver_solution}
              target='_blank'
            >
              {intl.t('查看其他解决方案')}
            </a>,
          })}
          <i
            className={cx('dk-iconfont', 'dk-icon-chouti-guanbi', 'icon-close')}
            onClick={() => message.destroy()}
         />
        </span>, 0);
        onClose();
      }
      return code;
    })
  }

  const currRole = useMemo(() => {
    if (isDk) {
      return DkOptionGlobal().filter((item) => item.value === roleKey.value)[0] ?? {}
    } return roleKey;
  }, [isDk, DkOptionGlobal(), roleKey])


  const applyOwner = async () => {
    try {
      const codeRes = await checkOwner();
      if (codeRes !== 10000) return;
      applyOwnerPermFn({
        resourceId,
        reason,
      }).then((res) => {
        const { approver = {}, bpmUrl, code } = res;
        if (code === 201034) { // 外部租户，暂无审批人
          message.destroy();
          message.error(intl.t('暂无审批人，可联系相关滴滴员工提交申请'));
        } else {
          cooperConfirm({
            title: intl.t('申请已提交'),
            content: <span>
              {intl.t('你的申请已提交，将由{slot0}进行审批。审批完成后，你将会收到消息通知', {
                slot0: <a
                  style={{ color: '#127FF0' }}
                  href={`dchat://im/start_conversation?name=${approver.ldap}`}
            >
                  {approver.cnName}
                </a>,
              })}
            </span>,
            onOk: () => {
              openNewWindow(bpmUrl);
            },
            okText: intl.t('查看详情'),
            cancelText: intl.t('知道了'),
          });
        }
        onClose();
      })
    } catch (err) {
      // TODO:接口对于无权限没有处理
      message.destroy();
      if (err.errorCode === 100005 && err.message === '资源不存在') {
        message.error(intl.t('文档已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else if (err.errorCode === 100005 && err.message === '没有申请权限') {
        message.error(intl.t('您无法访问该文档，不能申请更多权限'));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        message.error(err.errorMessage);
      }
    }
  }

  const applyOther = ({ isReApply }) => {
    let param = {
      message: reason,
      resourceId,
      isReApply: isReApply || false,
    };
    if (choosedData.type === 'multiple') {
      let arr = [];
      choosedData.value.split(',').forEach((i) => {
        if (!(roleKey.value.split(',') || []).includes(i)) {
          arr.push(PERM_FORMATE[i]);
        }
      });
      param.cooperationPerm = arr.join(',');
    } else {
      param.adminPerm = choosedData.value;
    }

    applyHigherPermFn(param).then((res) => {
      const { code, perm: permPre } = res;
      if (code === 200) {
        message.success(intl.t('您的申请已提交'));
        onClose();
      } else if (code === 200001) { // 自己申请过所有者，又申请其他权限
        cooperConfirm({
          title: intl.t('是否提交新申请?'),
          zIndex: 2001,
          content: isDk ? intl.t('您已经申请了页面的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: intl.t('所有者'),
            nextPrem: choosedData.text,
          }) : intl.t('您已经申请了文档的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: intl.t('所有者'),
            nextPrem: choosedData.text,
          }),
          onOk: () => onApply({ isReApply: true }),
          okText: intl.t('确认'),
          cancelText: intl.t('取消'),
          className: 'double-confirmation',
          getContainer: () => document.querySelector('.base-service-modal'),
        });
      } else if (code === 200003) { // 自己申请过其他权限，再申请其他权限（除所有者之外）
        cooperConfirm({
          title: intl.t('是否提交新申请?'),
          zIndex: 2001,
          content: isDk ? intl.t('您已经申请了页面的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: getExitRole(permPre),
            nextPrem: choosedData.text,
          }) : intl.t('您已经申请了文档的{prePerm}权限，目前还在审批中。是否取消原先申请流程，重新提交{nextPrem}权限的申请？', {
            prePerm: getExitRole(permPre),
            nextPrem: choosedData.text,
          }),
          onOk: () => onApply({ isReApply: true }),
          okText: intl.t('确认'),
          cancelText: intl.t('取消'),
          className: 'double-confirmation',
          getContainer: () => document.querySelector('.base-service-modal'),
        });
      } else if (code === 200004) { // 暂无审批人
        if (choosedData.value === (isDk ? ADMIN_PERM_DK : ADMIN_PERM)) {
          message.destroy();
          message.error(isDk ? intl.t('暂无审批人,建议申请页面所有权') : intl.t('暂无审批人,建议申请文档所有权'));
        } else {
          message.destroy();
          message.error(<span className={cx('success-tip')}>
            {intl.t('暂无审批人，{slot0}', {
              slot0: <a
                style={{ color: '#127FF0' }}
                href={isDk ? globalOutsideChain.no_approver_solution_dk : globalOutsideChain.no_approver_solution}
                target='_blank'
              >
                {intl.t('查看其他解决方案')}
              </a>,
            })}
            <i
              className={cx('dk-iconfont', 'dk-icon-chouti-guanbi', 'icon-close')}
              onClick={() => message.destroy()}
             />
          </span>, 0);
          onClose();
        }
      } else if (code === 200005) { // 文档被删除
        message.destroy();
        message.error(isDk ? intl.t('页面已被删除，3s后为你自动刷新页面') : intl.t('文档已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (code === 200006) { // 文档无权限 - 待测
        message.destroy();
        message.error(isDk ? intl.t('您无法访问该页面，不能申请更多权限') : intl.t('您无法访问该文档，不能申请更多权限'));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }).catch((error) => {
      const { errorCode, errorMessage } = error;
      if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
        message.destroy();
        message.error(isDk ? intl.t('页面已被删除，3s后为你自动刷新页面') : intl.t('文档已被删除，3s后为你自动刷新页面'));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        message.destroy();
        message.error(errorMessage);
      }
    });
  }


  const onApply = async ({ isReApply }) => {
    if (JSON.stringify(choosedData) === '{}' || choosedData.value === roleKey.value) {
      message.destroy();
      message.error(intl.t('申请的权限与你当前的权限一致，请检查后再提交'));
      return;
    }
    if (!reason) {
      message.destroy();
      message.error(intl.t('请填写申请原因'));
      return;
    }
    if (choosedData.value === (isDk ? OWNER_PERM_DK : OWNER_PERM)) {
      applyOwner();
    } else {
      applyOther({ isReApply })
    }
  }

  return <Modal
    visible={true}
    maskClosable={false}
    destroyOnClose
    wrapClassName={'base-service-modal'}
    title={intl.t('申请更多权限')}
    footer={
      <div className={cx('footer-wrap')}>
        <a
          className={cx('explanation')}
          target='_blank'
          href={isDk ? globalOutsideChain.approver_help_dk : globalOutsideChain.approver_help}>{ intl.t('谁是审批人？')}</a>
        <div className={cx('btn-content')}>
          <Button
            onClick={onClose}>{intl.t('取消')}</Button>
          <Button
            className={cx('btn-blue')}
            style={{ marginLeft: 20 }}
            type='primary'
            onClick={onApply}
            // disabled={JSON.stringify(choosedData) === '{}' || !reason}
          >
            {intl.t('提交申请')}
          </Button>
        </div>
      </div>
    }
    onCancel={onClose}

  >
    <div className={cx('label-item')}>
      <p className={cx('apply-body-perm', 'label-text')}>
        {intl.t('你在当前{slot0}的权限为{slot1}，申请获得以下权限', {
          slot0: isDk ? intl.t('页面') : intl.t('文档'),
          slot1: <span className={cx('curr-perm')}>{roleKey.label}</span>,
        })}
        <span className={cx('require')}>*</span>
      </p>
      <ChooseMemberPerm
        currRole={currRole}
        applyedPermObj={choosedData} // value 1,2,3,4   或者 role  string
        permType={permType}
        setChoosedData={(choosed) => { // 一个value 一个 text
          setChoosedData(choosed);
        }}
        isDk={isDk}
        // perm={perm}


      />
    </div>

    <div className={cx('label-item')}>
      <p className={cx('apply-body-reason', 'label-text')}>
        {intl.t('申请原因')}
        <span className={cx('require')}>*</span>
      </p>
      <TextArea
        onChange={(e) => setReason(e.target.value)}
        placeholder={intl.t('必填，最多200字')}
        maxLength={200}
        className={cx('apply-input')}
        value={reason}
        autoSize={false}
        style={{
          height: 100,
          resize: 'none',
        }}
      />
    </div>
  </Modal>;
};

export default ApplyPermModal;
