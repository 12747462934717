// import omegaTracker from '@didi/omega-tracker-lite'
import MarkdownIt from 'markdown-it'
import { getLocale } from './locales';
import { OmegaEventId } from './omega';

const md = MarkdownIt()

const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, _env, self) {
  return self.renderToken(tokens, idx, options);
};

// md链接跳新页
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const token = tokens[idx];
  // 给链接添加 target="_blank" 属性
  token.attrSet('target', '_blank');
  return defaultRender(tokens, idx, options, env, self);
};


export const renderMarkdown = (src: string) => {
  if (!src) return ''
  return md.render(src).trim()
}

// const Omega = omegaTracker.getTracker({
//   appKey: 'omega960d913d18',
//   autoClick: false,
//   hashRouterEnable: false, // 一般 pc web 建议关闭自动定位
//   browserRouterEnable: false,
//   autoSendPageView: false,
//   userName: getUserNameFromCookie(),
//   lcpFilter(dom: any) {
//     return /js-wartermark/.test(dom.id)
//   },
// })

export function uuid() {
  return Math.random().toString(16).slice(2)
}

// omega 埋点文档
export function getUserNameFromCookie() {
  const m = document.cookie?.match(/username=([^;]*)/)
  return decodeURIComponent(m ? m[1] : '')
}

export function getPlatform() {
  const { pathname } = window.location
  if (pathname.includes('/knowledge/')) {
    return 'knowledge'
  }
  if (pathname.includes('/docs2/')) {
    return 'shimo2.0'
  }
  if (pathname.includes('/docs/')) {
    return 'shimo1.5'
  }
  return ''
}

const platform = getPlatform()

export function omegaEvent(eventId: string, eventLabel = '', attrs = {}) {
  // @ts-ignore
  if (!window.__OmegaEvent) {
    console.error("Omega 没有初始化")
  } else {
    // @ts-ignore
    window.__OmegaEvent(eventId, eventLabel, {
      platform,
      ...attrs,
    })
  }
  // Omega?.trackEvent(eventId, eventLabel, {
  //   platform,
  //   ...attrs,
  // })
}

export const findLast = <T>(array: T[], callback: (item: T) => boolean) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (callback(array[i])) {
      return array[i]
    }
  }
  return null
}

export function pad(str: string | number, total = 2, padStr = '0') {
  return str.toString().padStart(total, padStr)
}

export const formatTimestamp = (timestamp: number) => {
  const timer = new Date(timestamp)
  const year = timer.getFullYear()
  const month = timer.getMonth() + 1 // 由于月份从0开始，因此需加1
  const day = timer.getDate()
  const hour = timer.getHours()
  const minute = timer.getMinutes()

  const currentYear = new Date().getFullYear()
  const locale = getLocale()
  if (currentYear === year) {
    return locale === 'zh-CN' ? `${month}月${day}日 ${pad(hour)}:${pad(minute)}` : `${month}-${day} ${pad(hour)}:${pad(minute)}`
  }

  return locale === 'zh-CN' ? `${year}年${month}月${day}日 ${pad(hour)}:${pad(minute)}` : `${year}-${month}-${day}- ${pad(hour)}:${pad(minute)}`
}

export const validEmoji = (str: string) => {
  const EmojiReg = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/

  return EmojiReg.test(str)
}

export const findLastChildElement = (ele: Element | null) => {
  if (!ele) return

  let result = ele

  while (result.lastElementChild) {
    result = result.lastElementChild
  }

  if (result.nextSibling && result.nextSibling.textContent?.trim()) {
    result = result.parentElement!
  }

  return result
}

export const isInIframe = window.top !== window.self

// 判断埋点位置
export const onFeedbackClickOmegaEvent = () => {
  if (document.querySelector('.ReactModalPortal')) {
    // Cooper文件
    omegaEvent(OmegaEventId.document, '', {})
  } else if (location.pathname.startsWith('/disk')) {
    // 个人空间
    omegaEvent(OmegaEventId.personal, '', {})
  } else if (location.pathname.startsWith('/team-file')) {
    // 团队空间
    omegaEvent(OmegaEventId.team, '', {})
  } else {
    console.error('Not Found EventId', location.pathname)
  }
}

// @ts-ignore
export const isDesktopDC = Boolean(window.dcJSSDK);

export const isSafari = (() => {
  const ua = window.navigator.userAgent;
  return /Safari/.test(ua) && !/Chrome/.test(ua);
})();

export const isIpad = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isIphone = ua.indexOf('iphone') !== -1 && ua.indexOf('version') !== -1;
  const isIPad = isSafari && !isIphone && 'ontouchend' in document;
  return isIPad || /ipad/i.test(ua);
};