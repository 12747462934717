
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import { Popover, Checkbox, Divider, Tooltip } from 'antd';
import { Fragment, useState, useMemo, useEffect } from 'react';
import { intl } from 'di18n-react';

const cx = classBind.bind(styles);

const SearchType = (props) => {
  const { rowKey, valueMap, filterMsg, updateFilteredMsg, doFilters, filterInfo } = props;
  const { initFilter, initTenantFilter, filterAll, filterTenantAll } = filterInfo;
  const [typeVisible, setTypeVisible] = useState(false);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  const [propsKey, setPropsKey] = useState(0);
  // 工具函数，生成所需的常量
  const getInitFilter = () => {
    if (IsExternalTenant) {
      return initTenantFilter
    }
    return initFilter
  }
  const getFilterAll = () => {
    if (IsExternalTenant) {
      return filterTenantAll
    }
    return filterAll
  }
  const filterToolitip = () => {
    return (
      <div className={cx('filter-tip-hover-content')}>
        <p className={cx('filter-tip-hover-content-title')}>
          {intl.t('类型说明')}
        </p>
        {IsExternalTenant ? (
          <>
            <p>{intl.t('文档：Cooper协作文档')}</p>
            <p>{intl.t('表格：Cooper协作表格')}</p>
            <p>{intl.t('文件：本地上传的Doc、Excel、PDF等文件')}</p>
          </>
        ) : <>
          <p>{intl.t('文档：Cooper协作文档、匿名文档、知识库文档')}</p>
          <p>{intl.t('表格：Cooper协作表格')}</p>
          <p>{intl.t('流程图：Cooper协作流程图')}</p>
          <p>{intl.t('思维导图：Cooper协作思维导图')}</p>
          <p>{intl.t('幻灯片：Cooper协作幻灯片')}</p>
          <p>{intl.t('文件：Cooper、知识库上传的本地文件')}</p>
          {initFilter.filter((item) => item.value === 'wiki').length !== 0 && <p>{intl.t('Wiki页面：Wiki页面')}</p>}
        </>}
      </div>
    );
  };

  const checkArrIsSame = (initFileArr) => {
    let filterArr = initFileArr.filter((item) => filterMsg.includes(item));
    return filterArr.length === initFileArr.length;
  };

  const handleFilters = (typeData, checkedValue) => {
    let obj = updateFilteredMsg(typeData, checkedValue.join(','));
    setPropsKey(propsKey + 1);
    doFilters(obj, typeData);
  };

  const handleClickFilter = debounce(
    (value, typeData) => {
      let checkedValue = filterMsg
      if (!checkedValue.includes(value)) {
        if (value === 'all') {
          checkedValue = ['all'];
        } else {
          checkedValue.push(value);
          if (checkedValue.includes('all')) {
            checkedValue.splice(checkedValue.indexOf('all'), 1);
          }
          if (checkArrIsSame(getFilterAll())) {
            checkedValue = ['all'];
          }
        }
        handleFilters(typeData, checkedValue);
      } else if (checkedValue.length === 1) {
        return false;
      } else {
        checkedValue.splice(checkedValue.indexOf(value), 1);
        handleFilters(typeData, checkedValue);
      }
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  const onVisibleChange = (visible) => {
    setTypeVisible(visible);
  }

  const resentFilter = debounce(
    (typeData) => {
      let currValue = getInitFilter();
      if (checkArrIsSame(currValue)) return;
      handleFilters(typeData, currValue);
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );
  const getFilterInfoName = () => {
    if (filterMsg.length > 1) {
      return `${intl.t('类型')}(${filterMsg.length})`;
    }

    return valueMap
      .filter((item) => item.value === filterMsg[0])[0]
      ?.text();
  };
  const renderFilterModal = (type) => {
    return (
      <ul
        className={cx('custom-filter-dropdown')}
        key={propsKey}>
        <li
          className={cx({
            'filter-resent': true,
            'filter-action': !checkArrIsSame(getInitFilter()),
          })}
          onClick={() => resentFilter(type)}
        >
          {intl.t('重置')}{' '}
        </li>
        <Divider className={cx('filter-divider')} />
        <li className={cx('filter-tip')}>
          {intl.t('类型可多选')}
          <Tooltip
            title={filterToolitip()}
            overlayClassName="filter-tooltip">
            <i className={cx('dk-iconfont', 'dk-icon-a-tishi2', 'icon-tip')} />
          </Tooltip>
        </li>
        {valueMap.map((item) => {
          return (
            <li key={item.value}>
              <Checkbox
                checked={filterMsg.includes(item.value)}
                onChange={() => handleClickFilter(item.value, type)}
                value={item.value}>
                {item.text()}
              </Checkbox>
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <Popover
      content={renderFilterModal('type')}
      placement="bottomLeft"
      trigger="click"
      visible={typeVisible}
      onVisibleChange={onVisibleChange}
      zIndex={99}
      getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode}
      key={rowKey}
  >
      <div
        className={cx({
          'file-handle': true,
          'file-checked': !filterMsg.includes('all') || typeVisible,
        })}
    >
        <Fragment>
          {getFilterInfoName()}
          <i
            className={cx({
              'dk-iconfont': true,
              'dk-icon-shaixuan2': !filterMsg.includes('all'),
              'icon-shaixuan2': true,
              'dk-icon-zhankai': filterMsg.includes('all'),
            })}
        />
        </Fragment>
      </div>
    </Popover>
  )
}

export default SearchType
