/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-19 14:08:04
 * @LastEditTime: 2024-04-11 17:14:20
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/utils/ab.js
 *
 */
/* eslint-disable import/extensions */
import { getUserNameFromCookie } from './index';
import { getLocale } from 'di18n-react';

export async function apolloSwitch(name = '') {
  const url = process.env.APP_ENV === 'prod' ? 'as.xiaojukeji.com' : 'astest.intra.xiaojukeji.com';

  try {
    const res = await fetch(`https://${url}/ep/as/feature?name=${name}&_cooper_username=${getUserNameFromCookie()}&key=${getUserNameFromCookie()}&__caller=di-knowledge`);
    const { data } = await res.json();
    if (data && data[0] && data[0].allow) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export async function apolloSwitchCb(name = '', passCb = () => { }, failCb = () => { }) {
  const url = process.env.APP_ENV === 'prod' ? 'as.xiaojukeji.com' : 'astest.intra.xiaojukeji.com';

  try {
    const res = await fetch(`https://${url}/ep/as/feature?name=${name}&_cooper_username=${getUserNameFromCookie()}&key=${getUserNameFromCookie()}&__caller=di-knowledge`);
    const { data } = await res.json();
    if (data && data[0] && data[0].allow) {
      passCb()
    } else {
      failCb();
    }
  } catch (e) {
    failCb();
  }
}


// 配置：https://ab.intra.xiaojukeji.com/conf/1/3272/3826
// 调用：https://git.xiaojukeji.com/zhuxinliang/apollo-sdk-react/blob/master/src/utils/httpApi.ts
export async function getAnnounce() {
  const name = process.env.APP_ENV === 'production' ? 'cooper' : 'cooper-test';
  try {
    const res = await fetch(
      `https://as.xiaojukeji.com/ep/as/conf?ns=cooper_announce&name=${name}&__caller=cooper`,
      { credentials: 'same-origin' },
    );
    const { data } = await res.json();
    if (data.length > 0) {
      const en = getLocale() === 'en-US' ? '_en' : '';
      return data[0].params[`announcement${en}`] || data[0].params.announcement;
    }
    return '';
  } catch (e) {
    return '';
  }
}

export async function getApolloConfig(name) {
  const url = process.env.APP_ENV === 'prod' ? 'as.xiaojukeji.com' : 'astest.intra.xiaojukeji.com';

  try {
    const res = await fetch(
      `https://${url}/ep/as/conf?ns=cooper_announce&name=${name}&__caller=cooper&raw=true`,
      { credentials: 'same-origin' },
    );
    const { data } = await res.json();
    if (data.length > 0) {
      return data[0]?.params;
    }
    return {};
  } catch (e) {
    return {};
  }
}

// apollo switch names
export const cooperIntranetDetector = 'cooperIntranetDetector';

// apollo switch names
export const AnonymousDocs = 'AnonymousDocs';

// export const HandNotes = 'Hand_Notes';
export const LibraryCooper = 'LibraryCooper';

export const ShimoDoc2 = 'ShimoDoc2_DChat';

export const HideCooperTome = 'HideCooperTome';

export const CooperPOCreate = 'create_processon_gray';
