import { intl } from 'di18n-react';
// eslint-disable-next-line no-unused-vars
import { ImportCooper, ImportWiki, ImportWikiHtml, ImportWord } from '@/assets/icon/index';

const option1 = function () {
  return [
    // {
    //   key: 0,
    //   title: intl.t('导入Wiki压缩包'),
    //   desc: intl.t('将Wiki页面内容导入知识库'),
    //   image: ImportWiki,
    //   menuFlag: 'wiki',
    //   show: true,
    // },
    {
      key: 2,
      title: intl.t('导入Word文件'),
      desc: intl.t('从本地上传Word文件导入知识库'),
      image: ImportWord,
      menuFlag: 'word',
      show: true,
    },
    // {
    //   key: 4,
    //   title: intl.t('导入Wiki html页面'),
    //   desc: intl.t('将Wiki html页面内容导入知识库'),
    //   image: ImportWikiHtml,
    //   menuFlag: 'confluence',
    //   show: window.cooperImportWikiAsHtml,
    // },
    {
      key: 5,
      title: intl.t('导入Wiki页面'),
      desc: intl.t('将Wiki页面内容导入知识库'),
      image: ImportWikiHtml,
      menuFlag: 'confluence',
      show: true,
    },
  ].filter((s) => s.show)
};

const option2 = function () {
  return [
    // {
    //   key: 0,
    //   title: intl.t('导入Wiki压缩包'),
    //   desc: intl.t('将Wiki页面内容导入知识库'),
    //   image: ImportWiki,
    //   menuFlag: 'wiki',
    //   show: true,
    // },
    // {
    //   key: 4,
    //   title: intl.t('导入Wiki压缩包'),
    //   desc: intl.t('将Wiki页面内容以HTML形式导入知识库'),
    //   image: ImportWiki,
    //   menuFlag: 'confluence',
    // },
    {
      key: 1,
      title: intl.t('导入Cooper文档'),
      desc: intl.t('从Cooper空间选择协作文档'),
      image: ImportCooper,
      menuFlag: 'cooper',
      show: true,
    },
    {
      key: 2,
      title: intl.t('导入Word文件'),
      desc: intl.t('从本地上传Word文件导入知识库'),
      image: ImportWord,
      menuFlag: 'word',
      show: true,
    },
    // {
    //   key: 4,
    //   title: intl.t('导入Wiki html页面'),
    //   desc: intl.t('将Wiki html页面内容导入知识库'),
    //   image: ImportWikiHtml,
    //   menuFlag: 'confluence',
    //   show: window.cooperImportWikiAsHtml,
    // },
    {
      key: 5,
      title: intl.t('导入Wiki页面'),
      desc: intl.t('将Wiki页面内容导入知识库'),
      image: ImportWiki,
      menuFlag: 'confluence',
      show: true,
    },
  ].filter((s) => s.show)
};
// 这里写两遍主要因为i18n翻译，初始化const时候会i18n还未初始化完毕
const IMPORTLIST = function () {
  return window.closeImportCooper ? option1() : option2();
}
export default IMPORTLIST;
