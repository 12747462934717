/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 10:54:23
 * @LastEditTime: 2023-09-13 21:52:00
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/StarFile/index.js
 *
 */
import { intl } from 'di18n-react';
import { message } from 'antd';
import { post } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';
import { FileType } from '@/constants/cooper';

async function switchStar(resourceIds, star) {
  const data = await post(CooperApi.STAR_LIST, {
    list: resourceIds,
    star,
  });
  return data;
}

async function starFile(files, doneCallback) {
  let star = 0;

  for (const f of files) {
    // 只要有一个未收藏，则收藏，否则取消收藏
    if (f.marked_star === 0 || f.marked_star === false) star = 1;
  }
  const ids = files.filter((f) => f.marked_star !== star).map((f) => {
    // if (f.resource_type === FileType.DK_PAGE || f.resource_type === FileType.DK_FILE) {
    //   return {
    //     resourceId: f.id,
    //     starType: f.star_type,
    //     starInfo: f.star_info,
    //   }
    // }
    const appId = f.resourceType === FileType.DK_PAGE || f.resourceType === FileType.DK_FILE || f.type === 7 ? 4 : 2;
    return {
      resourceId: f.id,
      starType: 'RESOURCE',
      starInfo: '',
      appId,
    }
  });
  await switchStar(ids, star).then(() => {
    message.success(star === 1 ? intl.t('收藏成功') : intl.t('已取消收藏'));
    doneCallback();
  });
}

export default starFile;
