import classBind from 'classnames/bind';
import SelectAnyOption from '@/components/common/SelectAnyOption/index'
import NormalOptions from '@/components/common/SelectAnyOption/NormalOptions';
import UserItem from '@/components/common/ListOptions/UserItem';
import styles from './style.module.less';
import { Checkbox, Divider, Tooltip, Popover } from 'antd';
import { intl } from 'di18n-react';
import { getUserData } from '@/service/knowledge/global';
import { getUserUidFromCookie, getTenantIdFromCookie } from '@/utils/index';
import { Fragment, useState } from 'react';
import Tag from '@/components/Tag';
import ReactDOM from 'react-dom';

const cx = classBind.bind(styles);

const SearchOwnerFilter = (props) => {
  const type = 'owner'
  const { filterMsg, updateFilteredMsg, doFilters, isNeedAlowMe = true } = props;
  const [initValue, setInitValue] = useState('Initial value');
  const [ownerVisible, setOwnerVisible] = useState(false);

  const handleClick = () => {
    ReactDOM.flushSync(() => {
      setInitValue('Updated value');
    });
  };

  const changeDisObj = (vObj) => {
    if (!vObj.memberId) {
      return false;
    }
    window.__OmegaEvent('ep_ownerfilter_choose_ck');
    let selectValue = {
      value: vObj?.ldap,
      label: vObj?.title?.replace(/§§/g, ''),
      id: vObj?.memberId,
      checked: true,
      tenantId: `${vObj?.orgId}`,
      showOuter: vObj?.showOuter,
    };
    const checkedValue = filterMsg
    // eslint-disable-next-line max-len
    let filterItem = checkedValue.filter((item) => item.id === selectValue?.id && item.tenantId === selectValue?.tenantId)
    if (filterItem.length === 0) {
      checkedValue.push(selectValue)
      const obj = updateFilteredMsg(type, checkedValue)
      doFilters(obj, checkedValue)
    } else if (!filterItem[0].checked) {
      checkedValue.map((item) => {
        if (item.id === selectValue.id && item.tenantId === selectValue.tenantId) {
          item.checked = true
        }
      })
      const obj = updateFilteredMsg(type, checkedValue)
      doFilters(obj)
    }
  }
  const selectListItemChange = (e) => {
    const { checked, value } = e.target
    const checkedValue = filterMsg
    checkedValue.map((item) => { item.id === value && (item.checked = checked) })
    const obj = updateFilteredMsg(type, checkedValue)
    doFilters(obj)
  }
  const selectIsMe = (e) => {
    const { checked, value } = e.target
    const selectValue = {
      value: '',
      label: '',
      id: value,
      checked: true,
      tenantId: getTenantIdFromCookie(),
      showOuter: false,
    }
    const checkedValue = filterMsg
    // eslint-disable-next-line max-len
    let filterItem = checkedValue.filter((item) => item.id === selectValue?.id && item.tenantId === selectValue?.tenantId)
    if (filterItem.length === 0) {
      checkedValue.push(selectValue)
      const obj = updateFilteredMsg(type, checkedValue)
      doFilters(obj)
    } else {
      checkedValue.map((item) => {
        if (item.id === value && item.tenantId === getTenantIdFromCookie()) {
          item.checked = checked
        }
      })
      const obj = updateFilteredMsg(type, checkedValue)
      doFilters(obj)
    }
  }
  const resentFilter = () => {
    const checkedValue = filterMsg
    checkedValue.map((item) => { item.checked = false })
    const obj = updateFilteredMsg(type, checkedValue)
    doFilters(obj)
  }
  const removeSelectItem = (handleItem) => {
    const checkedValue = filterMsg
    // eslint-disable-next-line max-len
    checkedValue.splice(checkedValue.findIndex((item) => item.id === handleItem.id && item.tenantId === handleItem.tenantId), 1)
    if (handleItem.checked) {
      const obj = updateFilteredMsg(type, checkedValue)
      doFilters(obj)
    } else {
      handleClick()
      updateFilteredMsg(type, checkedValue)
      setInitValue('Initial value')
    }
  }
  const filterToolitip = () => {
    return <div>
      <p>{intl.t('点击重置，筛选条件变为”不限归属“')}</p>
    </div>
  }
  const handleOpenOwner = (newOpen) => {
    setOwnerVisible(newOpen);
  };

  const getFilterOwnerContent = () => {
    const filterItemArr = filterMsg.filter((item) => item?.checked)
    const isMe = filterItemArr.length === 1 && filterItemArr[0].id === getUserUidFromCookie()
    const notMeOnlyOne = filterItemArr.length === 1 && filterItemArr[0].id !== getUserUidFromCookie()
    let text = ''
    if (isNeedAlowMe) {
      text = isMe ? intl.t('归我所有') : notMeOnlyOne ? filterItemArr[0].label : `${intl.t('所有者')}${filterItemArr.length === 0 ? '' : `(${filterItemArr.length})`}`
    } else {
      text = filterItemArr.length === 0 ? intl.t('不限归属') : notMeOnlyOne ? filterItemArr[0].label : `${intl.t('所有者')}${filterItemArr.length === 0 ? '' : `(${filterItemArr.length})`}`
    }
    return (
      <Fragment>
        <span className={cx('filter-owner-text')}>{text}</span>
        <i
          className={cx({
            'dk-iconfont': true,
            'dk-icon-zhankai': filterItemArr.length === 0,
            'icon-zhankai1': filterItemArr.length === 0,
            'dk-icon-shaixuan2': filterItemArr.length > 0,
            'icon-shaixuan2': filterItemArr.length > 0,
          })}
        />
      </Fragment>
    )
  }
  const renderFilterModal = () => {
    return <div>
      <ul
        className={cx({ 'custom-filter-dropdown': true })}
        key={initValue}>
        <li
          className={cx({
            'filter-resent': true,
            'filter-action': filterMsg.filter((item) => { return item.checked }).length !== 0,
          })}
          onClick={() => {
            window.__OmegaEvent('ep_ownerfilter_reset_ck');
            filterMsg.filter((item) => { return item.checked }).length !== 0 && resentFilter(type);
          }}
        >
          {intl.t('重置')}
        </li>
        <Divider className={cx('filter-divider')} />
        <li className={cx({ 'filter-tip': true })}>
          {intl.t('所有者')}
          <Tooltip
            title={filterToolitip()}
            overlayClassName="filter-tooltip">
            <i className={cx('dk-iconfont', 'dk-icon-a-tishi2', 'icon-tip')} />
          </Tooltip>
        </li>
        <div className={cx('scroll-content')}>
          {
            isNeedAlowMe && <li className={cx('is-flow-me')}>
              <Checkbox
            // eslint-disable-next-line max-len
                checked={filterMsg.filter((item) => item.id === getUserUidFromCookie() && item.checked && item.tenantId === getTenantIdFromCookie()).length > 0}
                value={getUserUidFromCookie()}
                onChange={(e) => {
                  window.__OmegaEvent('ep_ownerfilter_byme_ck');
                  selectIsMe(e);
                }}> {intl.t('归我所有')} </Checkbox>
            </li>
          }
          {
            filterMsg && filterMsg.map((item) => {
              if (isNeedAlowMe && item.id !== getUserUidFromCookie()) {
                return <li
                  key={`${item.value}-${item.tenantId}`}
                  className={cx('filter-item')}
              >
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => { selectListItemChange(e) }}
                    value={item.id}>
                    <span className={cx('filter-item-text')}>
                      <span className={cx('filter-item-text-name')}> {item.label}</span>
                      {
                        item?.showOuter && <Tag
                          type='out-yellow'
                          text={intl.t('外部')}
                        />
                      }
                    </span>
                  </Checkbox>
                  <span className={cx('shanchu-btn')}><i
                    className={cx('dk-iconfont dk-icon-shanchu4')}
                    onClick={() => {
                      window.__OmegaEvent('ep_ownerfilter_delect_ck');
                      removeSelectItem(item)
                    }}/></span>
                </li>
              }
              if (!isNeedAlowMe) {
                return <li
                  key={`${item.value}-${item.tenantId}`}
                  className={cx('filter-item')}
              >
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => { selectListItemChange(e) }}
                    value={item.id}>
                    <span className={cx('filter-item-text')}>
                      <span className={cx('filter-item-text-name')}> {item.label}</span>
                      {
                        item?.showOuter && <Tag
                          type='out-yellow'
                          text={intl.t('外部')}
                        />
                      }
                    </span>
                  </Checkbox>
                  <span className={cx('shanchu-btn')}><i
                    className={cx('dk-iconfont dk-icon-shanchu4')}
                    onClick={() => {
                      window.__OmegaEvent('ep_ownerfilter_delect_ck');
                      removeSelectItem(item)
                    }}/></span>
                </li>
              }
            })
          }
        </div>
        <SelectAnyOption
          placeholder={intl.t('请输入所有者名称')}
          onChoose={(vObj) => {
            vObj && changeDisObj(vObj)
          }}
          showRecent={false}
          OptionRender={({ searchValue, onChooseCallback }) => {
            return (
              <NormalOptions
                itemRequest={getUserData}
                itemRender={(v) => <UserItem optionData={v} />}
                id={'owner-select'}
                searchValue={searchValue}
                onChooseCallback={onChooseCallback}
            />
            );
          }}
      />
      </ul>
    </div>
  }
  return (
    <Popover
      content={renderFilterModal('owner')}
      placement="bottomLeft"
      trigger="click"
      visible={ownerVisible}
      onVisibleChange={handleOpenOwner}
      getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode
    }
  >
      <div
        className={cx({
          'file-handle': true,
          'file-handle-name': true,
          'file-checked':
          // eslint-disable-next-line no-unused-expressions
          filterMsg.filter((item) => item?.checked).length !== 0 || ownerVisible,
        })}
    >
        {
          getFilterOwnerContent()
        }
      </div>
    </Popover>
  )
}

export default SearchOwnerFilter;
