import { useEffect, useState, useMemo, useCallback, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, Spin } from 'antd';
import classBind from 'classnames/bind';
import * as service from '@/service/knowledge/pageTree';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { formatTree, updateTreeData } from '@/pages/knowledge/ShareBook/CatalogTree/util';
import TreeNode from '@/pages/knowledge/ShareBook/CatalogTree/TreeNode';
import stylesCommon from '@/components/serviceComponents/AsideDK/catalogCommon.module.less';
import OutlinedIcon from '@/components/serviceComponents/CatalogTree/OutlinedIcon';
import { getUrlParams } from '@/utils/index';
import { apolloSwitch } from '@/utils/ab';

const cm = classBind.bind(stylesCommon);


function PageCatalogTree({ height }) {
  const { pageId: currPageId, shareId } = useContext(LayoutContext);
  const { docInfo } = useSelector((state) => state.SharePage);
  const dispatch = useDispatch();
  const { getIdsFormShare } = dispatch.SharePage;
  const { multiPath = [] } = docInfo;
  const [selectedKey, setSelectedKey] = useState('');
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [scrollKnowledgeGray, setScrollKnowledgeGray] = useState(false)

  const fromShareId = useMemo(() => {
    return getUrlParams('base') || shareId;
  }, [shareId, window.location.href]);

  const isSubPageShare = true;

  const initGrey = async () => {
    const data = await apolloSwitch('scroll_knowledge');
    setScrollKnowledgeGray(data);
  }


  useEffect(() => {
    // if (catalogContainer.current.offsetHeight) {
    //   setHeight(catalogContainer.current.offsetHeight);
    // }
    initGrey()
    initData();
  }, []);


  const initData = async () => {
    let param = {};
    let res = await getIdsFormShare({ shareId });
    if (getUrlParams('base')) {
      param = {
        pageIdP: res.pageId,
        shareIdP: getUrlParams('base'),
        typeP: 0,
      }
    } else {
      param = {
        pageIdP: '0',
        shareIdP: shareId,
        typeP: 0,
      }
    }

    reqTreeData({
      ...param,
    }).then((res) => {
      setTreeData(formatTree(res, isSubPageShare));
    }).catch((error) => {
    }).finally(() => {
      setLoading(false);
    })
  }

  const reqTreeData = async ({
    pageIdP,
    shareIdP,
    typeP,
  }) => {
    const fn = isSubPageShare ? service.getSubSharePageTreeData : service.getSharePageTreeData;
    const res = await fn({ pageId: pageIdP, shareId: shareIdP, type: typeP });
    return res;
  }


  useEffect(() => {
    if (multiPath.length) {
      setExpandedKeys([...new Set([...expandedKeys, ...multiPath.map((v) => {
        return isSubPageShare ? v.shareId : v.id
      })])]);
    }
  }, [multiPath]); // 保证treeData数据更新后，重新设置expandedKeys

  useEffect(() => {
    setExpandedKeys([...new Set([...expandedKeys])]);
  }, [treeData]);

  useEffect(() => {
    setSelectedKey(isSubPageShare ? shareId : currPageId);
  }, [isSubPageShare, currPageId, shareId]);

  const onSelect = (selectedKeys) => {
    window.__OmegaEvent('ep_dkpc_pageview_ck');
  };

  const onExpand = (v) => {
    setExpandedKeys(v);

    window.__OmegaEvent('ep_dkpc_page_xialaicon_ck');
  };

  const onLoadData = async ({ key, children, pageId }) => {
    if (children.length) {
      return;
    }
    reqTreeData({
      pageIdP: pageId,
      shareIdP: fromShareId,
      typeP: 1,
    }).then((res) => {
      setTreeData((list) => updateTreeData(list, key, formatTree(res, isSubPageShare)));
    }).catch((error) => {
    })
  };

  const renderDKTree = () => { 
    if (scrollKnowledgeGray) {

      return (
        <Tree
          draggable={false}
          height={height}
          className="draggable-tree"
          expandedKeys={expandedKeys}
          selectedKeys={[selectedKey]}
          treeData={treeData}
          switcherIcon={<OutlinedIcon />}
          onSelect={onSelect}
          onExpand={onExpand}
          blockNode
          loadData={onLoadData}
          titleRender={(nodeData) => (
            <TreeNode
              nodeData={nodeData}
              isSubPageShare={isSubPageShare}
              fromShareId={fromShareId}
            />
          )}

        />
      )
    } else {
      return (
        <Tree
          draggable={false}
          className="draggable-tree"
          expandedKeys={expandedKeys}
          selectedKeys={[selectedKey]}
          treeData={treeData}
          switcherIcon={<OutlinedIcon />}
          onSelect={onSelect}
          onExpand={onExpand}
          blockNode
          loadData={onLoadData}
          titleRender={(nodeData) => (
            <TreeNode
              nodeData={nodeData}
              isSubPageShare={isSubPageShare}
              fromShareId={fromShareId}
            />
          )}

        />
      )
    }
  }


  return (
    <div
      className={scrollKnowledgeGray ? `${cm('catalog-page-wrap-virtual')}` :  `${cm('catalog-page-wrap')}` }
      style={{ height: 'calc(100% - 206px)', padding: 0 }}
    // ref={catalogContainer}
    >
      {
        loading ? (
          <div className={cm('aside-catalog-loading')}>
            <Spin />
          </div>
        ) : renderDKTree()
      }
    </div>
  );
}


export default PageCatalogTree;
