import React from 'react'
import { intl } from 'di18n-react';

const backLastPos = () => {
  // @ts-ignore
  let scrollDoc = document.querySelector('#knowledge_editor_box');
  if (scrollDoc) {
    scrollDoc.scrollTop = localStorage.getItem("lastPos_" + location.pathname.split('/').pop())
  }
}


export default (props) => {
  return (
    <React.Fragment>
      <div className='icon-box icon-box-back-last-pos' onClick={backLastPos}>
        <i className='dk-iconfont dk-icon-dingwei-01' />{intl.t('回到上次位置')}
      </div>
    </React.Fragment>
  )
}
