/*
 * @Author: mayinlong
 * @Date: 2021-05-28 10:32:54
 * @LastEditTime: 2021-05-28 14:23:49
 * @FilePath: /cooper-web/web/app/components/v3/cooper-aside-dc/cooper-search-dc/dcSdk.js
 * @Description:
 * dc云空间搜索唤醒全局搜索sdk
 */

export const inDC = !!window.dcJSSDK;
export const inDCH5 = !!window.dcH5Sdk;

function checkDC() {
  return !!window.dcJSSDK;
}

export function openDCSearch() {
  if (!checkDC()) {
    return;
  }
  const { showGlobalSearch } = window.dcJSSDK;
  if (typeof showGlobalSearch === 'function') {
    showGlobalSearch({
      tab: 'document',
    });
  }
}

// 兼容老版本dc
export function isSupported() {
  const version = window.dcJSSDK?.version;
  if (!version) return false
  const [main, sub] = version && version.split('.');

  return main > 1 || (main > 0 && sub > 1);
}
